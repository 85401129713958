import HttpClient from "./apiClient";

export async function apiGetGoogleAnalyticsVisitors(params: {
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/google/api/visitors", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetGoogleKpisData(params: {
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/google/api/ads/kpi", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}