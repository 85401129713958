
import { LineChart, PolarisVizProvider } from "@shopify/polaris-viz";
import "@shopify/polaris-viz/build/esm/styles.css";
import { RiArrowDropUpFill, RiArrowDropDownFill } from "react-icons/ri";
import { IChartData, IRestApi } from '@/types/props';

interface Props {
  data: IRestApi;
}

export const PolarisVizLineChart = ({ data }: Props) => {
  return (
    <PolarisVizProvider
      themes={{
        Light: {
          legend: {
            backgroundColor: "white",
          },
          chartContainer: {
            borderRadius: '10px',
            padding: '55px 20px 10px',
            backgroundColor: 'white'
          },
        },
      }}
    >
      <div className='relative'>
        <LineChart data={data.chart} theme="Light" />
        <div className='absolute top-3.5 w-full px-5 flex justify-between'>
          <p className='font-semibold'>{data.label}</p>
          <div className='flex items-center'>
            {/* <p>431</p> */}
            <div className=' rounded bg-gray-200 flex justify-center items-center'>
              {data.percent > 0 ?
              <RiArrowDropUpFill color={'green'} size={25} /> :
              <RiArrowDropDownFill color={'red'} size={25} /> }
              <p className='text-xs font-semibold pr-2' style={{color: data.percent>0 ? 'green' : 'red'}}>{data.percent}%</p>
            </div>
          </div>
        </div>
      </div>
    </PolarisVizProvider>
  );
};