import CustomCard from "@/components/UI/CustomCard";
import "./index.styles.scss";
import { RootState } from "@/redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import appAction from "@/redux/app/action";
import { useEffect, useRef, useState } from "react";
import DatePicker from "@/components/UI/DatePicker";
import { PolarisVizLineChart } from "@/components/UI/Chart";
import {
  apiGetShopifyCustomersAll,
  apiGetShopifyAllOrders,
  apiGetShopifySales,
  apiGetShopifyCustomersNew,
  apiGetShopifyCustomersRepeat,
  apiGetShopifyRepurchaseFrequency
} from "@/shared/api/shopify";
import { apiGetGoogleKpisData, apiGetGoogleAnalyticsVisitors } from "@/shared/api/google";
import { apiGetMetaKpisData } from "@/shared/api/facebook";
import { findKpiByRange, mergeAndConsolidateArrays, mergeArraysByDate_googleMeta_sales, mergeSameArraysByDate, toISODateString, updateKpiData } from "@/shared/utils/utils";
import { IChartData, ISalesData, IRestApi } from "@/types/props";
import {
  calculateDivide,
  calculatePercentageChange,
  getTotalVisitors,
  getTotalValue,
  getTotalSales,
  sortDataByDate,
  calculatePercentage
} from "@/shared/utils/shopify";
import { showToast } from "@/components/UI/PrimaryToast";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import PromptChannel from "@/components/App/PromptChannel";
import { Channel } from "@/types/enum";
import { Carousel } from 'react-responsive-carousel';
import PromptResponsePage from "@/pages/Prompt/Response";
import PromptLibraryPage from "@/pages/Prompt/Library";
import ChannelSelect from "@/components/UI/ChannelSelect";
import Scrollbars from "react-custom-scrollbars-2";
import Header from "@/components/App/Header";
import { DateRange, IKPI } from "@/types/basic";
import CompareDateRange from "@/components/UI/CompareDateRange";
import CarouselItem from "@/components/UI/CarouselItem";

const initKpiData = [
  { label: "Total Sales", percent: 0, value: 0, currency: "$", chart: [] },
  { label: "Total Orders", percent: 0, value: 0, currency: "", chart: [] },
  { label: "Conv. Rate", percent: 0, value: 0, currency: "$", chart: [] },
  { label: "Visitors", percent: 0, value: 0, currency: "", chart: [] },
  { label: "AOV", percent: 0, value: 0, currency: "$", chart: [] },
  { label: "Repurchase Freq.", percent: 0, value: 0, currency: "", chart: [] },
  { label: "New Customer%", percent: 0, value: 0, currency: "%", chart: [] },
  { label: "Repeat Cust%", percent: 0, value: 0, currency: "", chart: [] },
  { label: "MER", percent: 0, value: 0, currency: "$", chart: [] },
  { label: "Blended ROAS", percent: 0, value: 0, currency: "$", chart: [] },
  { label: "CPA", percent: 0, value: 0, currency: "$", chart: [] },
  { label: "Total Ad Spend", percent: 0, value: 0, currency: "$", chart: [] }
]
const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState<IRestApi[]>(initKpiData);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | null>(null);
  const { isLoggedIn, shopify, dateRange, isResponsePage, shopifyKpiData } = useSelector((state: RootState) => state.app);
  const [cookies, setCookie] = useCookies(["shopify"]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedKpi, setSelectedKpi] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const shopifySalesData = useRef<any>(null);
  const googleVisitorsData = useRef<any>(null);
  const googleKpiData = useRef<any>(null);
  const metaKpiData = useRef<any>(null);
  const dateRangeRef = useRef<any>(null);

  useEffect(() => {
    if (!shopify?.shopName) {
      showToast("error", "Shopify ShopName not exist");
      setCookie("shopify", "fail");
      navigate("/connects");
    }
  }, [shopify]);

  useEffect(() => {
    if (isResponsePage) {
      setCurrentIndex(2);
      dispatch(appAction.onUpdateResponsePage(false));
    }
  }, [isResponsePage]);

  const goToPromptLibrary = () => {
    setCurrentIndex(1);
  };

  const goToPromptResponse = () => {
    setCurrentIndex(2);
  };

  const goToPrevious = () => {
    // setCurrentIndex((prevIndex) => (prevIndex - 1 + 3) % 3); // Assuming there are 3 slides
    setCurrentIndex(0);
  };

  const onSelectDateRange = async (range: DateRange) => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    const {startDate, endDate} = range;

    setSelectedDateRange(range);
    dateRangeRef.current = range;

    dispatch(appAction.onUpdateDateRange(range));
    // dispatch(appAction.onUpdateShopifyKpiData(undefined));
    setIsLoading(true);

    const kpiData = findKpiByRange(shopifyKpiData, range);
    if(kpiData) {
      setData(kpiData.data);
      setIsLoading(false);
      return;
    }
    setData(initKpiData);
    await getAllOrders(startDate, endDate);
    await getAllCustomers(startDate, endDate);
    await getRepurchaseFrequency(startDate, endDate);
    await getVisitors(startDate, endDate);
    await getConvRate(startDate, endDate);
    await getTotalAdSpendData(startDate, endDate);
    await getBlendedROAS();

    setIsLoading(false);
  };

  const onFinish = async (range: DateRange) => {
    const newKpiData: IKPI = {
      range: range,
      data: data,
      createdAt: new Date()
    }
    const updatedKpiData = updateKpiData(shopifyKpiData, newKpiData);
    dispatch(appAction.onUpdateShopifyKpiData(updatedKpiData));
  }

  useEffect(() => {
    if(data) {
      const hasEmptyCharts = data.some(item => item.chart.length === 0);
      if (!hasEmptyCharts) {
        const kpiData = findKpiByRange(shopifyKpiData, dateRangeRef.current);
        if(!kpiData) {
          // console.log('store new data ', dateRangeRef.current);
          onFinish(dateRangeRef.current);
        }
      }
    }
  }, [data])


  const getAllOrders = async (startDate: any, endDate: any) => {
    const res = await apiGetShopifyAllOrders({
      shopName: shopify?.shopName!
    });
    if (res?.success) {
      const res = await apiGetShopifySales({ startDate, endDate });
      if (res?.success) {
        // console.log('sales data ? ',res.data)
        shopifySalesData.current = res.data;
        calculateOrders(res.data);
      }
    }
  };

  // calculate total
  const calculateOrders = (salesData: any) => {
    const _salesInRange: ISalesData[] = sortDataByDate(salesData.salesInRange);
    const _salesBeforeRange: ISalesData[] = sortDataByDate(salesData.salesBeforeRange);
    // total sales
    let salesInRange: IChartData = {
      data: [],
      name: "Selected",
      color: "lightseagreen"
    };
    let salesBeforeRange: IChartData = {
      data: [],
      name: "Before",
      color: "lightgreen",
      isComparison: true
    };
    _salesInRange.forEach((item: any) => {
      salesInRange.data.push({ key: item.date, value: item.totalSales });
    });
    _salesBeforeRange.forEach((item: any) => {
      salesBeforeRange.data.push({ key: item.date, value: item.totalSales });
    });
    const totalSales_chart = [salesInRange, salesBeforeRange];
    const sales = getTotalSales(salesData.salesInRange);
    const beforeSales = getTotalSales(salesData.salesBeforeRange);
    const totalSalesPercent = calculatePercentageChange(sales, beforeSales);
    // total orders
    let totalOrdersInRange: IChartData = {
      data: [],
      name: "Selected",
      color: "lightseagreen"
    };
    let totalOrdersBeforeRange: IChartData = {
      data: [],
      name: "Before",
      color: "lightgreen",
      isComparison: true
    };
    _salesInRange.forEach((item: any) => {
      totalOrdersInRange.data.push({ key: item.date, value: item.orderCount });
    });
    _salesBeforeRange.forEach((item: any) => {
      totalOrdersBeforeRange.data.push({
        key: item.date,
        value: item.orderCount
      });
    });
    const totalOrders_chart = [totalOrdersInRange, totalOrdersBeforeRange];
    const totalOrders_current = getTotalValue(salesData.salesInRange, "orderCount");
    const totalOrders_before = getTotalValue(salesData.salesBeforeRange, "orderCount");
    const totalOrders_percent = calculatePercentageChange(totalOrders_current, totalOrders_before);
    // AOV
    let aovInRange: IChartData = {
      data: [],
      name: "Selected",
      color: "lightseagreen"
    };
    let aovBeforeRange: IChartData = {
      data: [],
      name: "Before",
      color: "lightgreen",
      isComparison: true
    };
    _salesInRange.forEach((item: any) => {
      aovInRange.data.push({
        key: item.date,
        value: calculateDivide(item.totalSales, item.orderCount)
      });
    });
    _salesBeforeRange.forEach((item: any) => {
      aovBeforeRange.data.push({
        key: item.date,
        value: calculateDivide(item.totalSales, item.orderCount)
      });
    });
    const AOV_chart = [aovInRange, aovBeforeRange];
    const AOV_current = calculateDivide(sales, totalOrders_current);
    const AOV_before = calculateDivide(beforeSales, totalOrders_before);
    const AOV_percent = calculatePercentageChange(AOV_current, AOV_before);

    setData((prev: any) => {
      return prev.map((item: any) => {
        if (item.label === "Total Sales") {
          return {
            label: item.label,
            value: sales,
            percent: totalSalesPercent,
            chart: totalSales_chart
          };
        }
        if (item.label === "Total Orders") {
          return {
            label: item.label,
            value: totalOrders_current,
            percent: totalOrders_percent,
            currency: "",
            chart: totalOrders_chart
          };
        }
        if (item.label === "AOV") {
          return {
            label: item.label,
            value: AOV_current,
            percent: AOV_percent,
            chart: AOV_chart
          };
        }
        return item;
      });
    });
  };

  const getRepurchaseFrequency = async (startDate: any, endDate: any) => {
    const res = await apiGetShopifyRepurchaseFrequency({
      shopName: shopify?.shopName!,
      startDate: startDate,
      endDate: endDate
    });
    if (res?.success) {
      const { repurchaseFrequencyInRange, repurchaseFrequencyBeforeRange } = res.data;
      const percent =
        repurchaseFrequencyBeforeRange.repurchaseFrequency !== null &&
          repurchaseFrequencyBeforeRange.repurchaseFrequency !== null
          ? calculatePercentageChange(
            repurchaseFrequencyInRange.repurchaseFrequency,
            repurchaseFrequencyBeforeRange.repurchaseFrequency
          )
          : 0;
      const _inRange: any = sortDataByDate(repurchaseFrequencyInRange.dailyRepurchaseFrequency);
      const _beforeRange: any = sortDataByDate(repurchaseFrequencyBeforeRange.dailyRepurchaseFrequency);
      let inRange: IChartData = {
        data: [],
        name: "Selected",
        color: "lightseagreen"
      };
      let beforeRange: IChartData = {
        data: [],
        name: "Before",
        color: "lightgreen",
        isComparison: true
      };
      _inRange.forEach((item: any) => {
        inRange.data.push({ key: item.date, value: item.repurchaseFrequency });
      });
      _beforeRange.forEach((item: any) => {
        beforeRange.data.push({
          key: item.date,
          value: item.repurchaseFrequency
        });
      });
      const chart = [inRange, beforeRange];
      setData((prev: any) => {
        return prev.map((item: any) => {
          if (item.label === "Repurchase Freq.") {
            return {
              label: item.label,
              value:
                repurchaseFrequencyInRange.repurchaseFrequency == null
                  ? 0
                  : repurchaseFrequencyInRange.repurchaseFrequency,
              percent: percent,
              currency: "",
              chart: chart
            };
          }
          return item;
        });
      });
    }
  };

  // Initialize Customers Data
  const getAllCustomers = async (startDate: any, endDate: any) => {
    const res = await apiGetShopifyCustomersAll({
      shopName: shopify?.shopName!,
    });
    if(res?.success) {
      await getNewCustomers(startDate, endDate);
      await getRepeatCustomers(startDate, endDate);
    }
  };

  // New Customers Rate
  const getNewCustomers = async (startDate: any, endDate: any) => {
    const res = await apiGetShopifyCustomersNew({
      shopName: shopify?.shopName!,
      startDate: startDate,
      endDate: endDate
    });
    if (res?.success) {
      // console.log('new customers ? ',res.data)
      const { rateInRange, rateBeforeRange } = res.data;
      const percent = calculatePercentageChange(rateInRange.newCustomers, rateBeforeRange.newCustomers);
      const _inRange: any = sortDataByDate(rateInRange.dailyCustomers);
      const _beforeRange: any = sortDataByDate(rateBeforeRange.dailyCustomers);
      let inRange: IChartData = {
        data: [],
        name: "Selected",
        color: "lightseagreen"
      };
      let beforeRange: IChartData = {
        data: [],
        name: "Before",
        color: "lightgreen",
        isComparison: true
      };
      _inRange.forEach((item: any) => {
        inRange.data.push({ key: item.date, value: item.rate }); //item.customers
      });
      _beforeRange.forEach((item: any) => {
        beforeRange.data.push({ key: item.date, value: item.rate }); //item.customers
      });
      const chart = [inRange, beforeRange];
      setData((prev: any) => {
        return prev.map((item: any) => {
          if (item.label === "New Customer%") {
            return {
              label: item.label,
              value: rateInRange.newCustomersRate,
              percent: percent,
              currency: "%",
              chart: chart
            };
          }
          return item;
        });
      });
    }
  };

  // Repeat Customers Rate
  const getRepeatCustomers = async (startDate: any, endDate: any) => {
    const res = await apiGetShopifyCustomersRepeat({
      shopName: shopify?.shopName!,
      startDate: startDate,
      endDate: endDate
    });
    if (res?.success) {
      // console.log(res.data)
      const { rateInRange, rateBeforeRange } = res.data;
      const percent = calculatePercentageChange(rateInRange.repeatCustomers, rateBeforeRange.repeatCustomers);
      const _inRange: any = sortDataByDate(rateInRange.dailyCustomers);
      const _beforeRange: any = sortDataByDate(rateBeforeRange.dailyCustomers);
      let inRange: IChartData = {
        data: [],
        name: "Selected",
        color: "lightseagreen"
      };
      let beforeRange: IChartData = {
        data: [],
        name: "Before",
        color: "lightgreen",
        isComparison: true
      };
      _inRange.forEach((item: any) => {
        inRange.data.push({ key: item.date, value: item.rate }); //item.customers
      });
      _beforeRange.forEach((item: any) => {
        beforeRange.data.push({ key: item.date, value: item.rate }); //item.customers
      });
      const chart = [inRange, beforeRange];
      setData((prev: any) => {
        return prev.map((item: any) => {
          if (item.label === "Repeat Cust%") {
            return {
              label: item.label,
              value: rateInRange.repeatCustomersRate,
              percent: percent,
              currency: "%",
              chart: chart
            };
          }
          return item;
        });
      });
    }
  };

  // get visitors google
  const getVisitors = async (startDate: any, endDate: any) => {
    const res = await apiGetGoogleAnalyticsVisitors({
      startDate: startDate,
      endDate: endDate
    });
    if (res?.success) {
      googleVisitorsData.current = res.data;
      const { visitorsInRange, visitorsBeforeRange } = res.data;
      const _visitorsInRange = sortDataByDate(visitorsInRange);
      const _visitorsBeforeRange = sortDataByDate(visitorsBeforeRange);
      const totalVisitors_inRange = getTotalVisitors(visitorsInRange);
      const totalVisitors_beforeRange = getTotalVisitors(visitorsBeforeRange);
      const percent = calculatePercentageChange(totalVisitors_inRange, totalVisitors_beforeRange);
      let inRange: IChartData = {
        data: [],
        name: "Selected",
        color: "lightseagreen"
      };
      let beforeRange: IChartData = {
        data: [],
        name: "Before",
        color: "lightgreen",
        isComparison: true
      };
      _visitorsInRange.forEach((item: any) => {
        inRange.data.push({ key: item.date, value: item.visitors });
      });
      _visitorsBeforeRange.forEach((item: any) => {
        beforeRange.data.push({ key: item.date, value: item.visitors });
      });
      const chart = [inRange, beforeRange];
      setData((prev: any) => {
        return prev.map((item: any) => {
          if (item.label === "Visitors") {
            return {
              label: item.label,
              value: totalVisitors_inRange,
              percent: percent,
              currency: "",
              chart: chart
            };
          }
          return item;
        });
      });
    }
  };

  const getConvRate = async (startDate: any, endDate: any) => {
    // console.log('get conv rate')
    // const responseSales = await apiGetShopifySales({
    //   startDate: startDate,
    //   endDate: endDate
    // });
    // const responseVisitors = await apiGetGoogleAnalyticsVisitors({
    //   startDate: startDate,
    //   endDate: endDate
    // });
    const responseSales = shopifySalesData.current;
    const responseVisitors = googleVisitorsData.current;

    // if (responseSales.success && responseVisitors.success) {
    //   const salesData = updateSalesData_visitors(responseVisitors.data, responseSales.data);
    if (responseSales && responseVisitors) {
      const salesData = updateSalesData_visitors(responseVisitors, responseSales);
      let _convRateInRange: IChartData = {
        data: [],
        name: "Selected",
        color: "lightseagreen"
      };
      let _convRateBeforeRange: IChartData = {
        data: [],
        name: "Before",
        color: "lightgreen",
        isComparison: true
      };

      salesData.salesInRange.forEach((item: any) => {
        _convRateInRange.data.push({
          key: item.date,
          value: calculatePercentage(item.orderCount, item.visitors)
        });
      });
      salesData.salesBeforeRange.forEach((item: any) => {
        _convRateBeforeRange.data.push({
          key: item.date,
          value: calculatePercentage(item.orderCount, item.visitors)
        });
      });
      const chart = [_convRateInRange, _convRateBeforeRange];
      const totalOrders_current = getTotalValue(salesData.salesInRange, "orderCount");
      const totalOrders_before = getTotalValue(salesData.salesBeforeRange, "orderCount");
      const totalVisitors_current = getTotalValue(salesData.salesInRange, "visitors");
      const totalVisitors_before = getTotalValue(salesData.salesBeforeRange, "visitors");

      const convRateInRange =
        totalVisitors_current === 0 ? 0 : calculatePercentage(totalOrders_current, totalVisitors_current);
      const convRateBeforeRange =
        totalVisitors_before === 0 ? 0 : calculatePercentage(totalOrders_before, totalVisitors_before);
      const percent = calculatePercentageChange(convRateInRange, convRateBeforeRange);
      // console.log('percent >>> ', percent)
      setData((prev: any) => {
        return prev.map((item: any) => {
          if (item.label === "Conv. Rate") {
            return {
              label: item.label,
              value: convRateInRange,
              percent: percent,
              currency: "%",
              chart: chart
            };
          }
          return item;
        });
      });
    }
  };

  // Function to update salesData based on visitorsData and add missing dates
  const updateSalesData_visitors = (visitorsData: any, salesData: any) => {
    const updatedSalesData = { ...salesData };

    const updateRange = (visitorsRange: any[], salesRange: any[], key: string) => {
      const visitorDates = visitorsRange.map((v: any) => v.date);
      const visitorsMap = new Map(visitorsRange.map((v: any) => [v.date, v.visitors]));

      // Create a map for sales data by date for quick lookup
      const salesMap = new Map(salesRange.map((sale: any) => [sale.date, sale]));

      // Generate updated sales range
      const updatedSalesRange = visitorDates.map((date: string) => {
        if (salesMap.has(date)) {
          const sale = salesMap.get(date);
          return {
            date: date,
            totalSales: sale.totalSales,
            orderCount: sale.orderCount,
            visitors: visitorsMap.get(date) || 0
          };
        } else {
          return {
            date: date,
            totalSales: 0,
            orderCount: 0,
            visitors: visitorsMap.get(date) || 0
          };
        }
      });

      // Sort the updated sales data by date
      updatedSalesRange.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());

      updatedSalesData[key] = updatedSalesRange;
    };

    updateRange(visitorsData.visitorsInRange, salesData.salesInRange, "salesInRange");
    updateRange(visitorsData.visitorsBeforeRange, salesData.salesBeforeRange, "salesBeforeRange");

    return updatedSalesData;
  };

  const getTotalAdSpendData = async (startDate: any, endDate: any) => {
    const responseMetaData = await apiGetMetaKpisData({
      startDate: startDate,
      endDate: endDate
    });
    const responseGoogleData = await apiGetGoogleKpisData({
      startDate: startDate,
      endDate: endDate
    });
    // console.log('responseMetaData ? ', responseMetaData);
    // console.log('responseGoogleAdSpendData ? ', responseGoogleData);
    if (responseMetaData?.success && responseGoogleData?.success) {
      metaKpiData.current = responseMetaData.data;
      googleKpiData.current = responseGoogleData.data;
      const meta_spend_inRange = responseMetaData.data.dataInRange;
      const meta_spend_beforeRange = responseMetaData.data.dataBeforeRange;
      const google_spend_inRange = responseGoogleData.data.dataInRange;
      const google_spend_beforeRange = responseGoogleData.data.dataBeforeRange;

      const _inRange = mergeAndConsolidateArrays(meta_spend_inRange, google_spend_inRange);
      const _beforeRange = mergeAndConsolidateArrays(meta_spend_beforeRange, google_spend_beforeRange);

      const totalSpend_current = getTotalValue(_inRange, "spend");
      const totalSpend_before = getTotalValue(_beforeRange, "spend");
      const percent = calculatePercentageChange(totalSpend_current, totalSpend_before);

      let inRange: IChartData = {
        data: [],
        name: "Selected",
        color: "lightseagreen"
      };
      let beforeRange: IChartData = {
        data: [],
        name: "Before",
        color: "lightgreen",
        isComparison: true
      };
      _inRange.forEach((item: any) => {
        inRange.data.push({ key: item.date, value: item.spend });
      });
      _beforeRange.forEach((item: any) => {
        beforeRange.data.push({ key: item.date, value: item.spend });
      });

      const chart = [inRange, beforeRange];

      setData((prev: any) => {
        return prev.map((item: any) => {
          if (item.label === "Total Ad Spend") {
            return {
              label: item.label,
              value: totalSpend_current,
              percent: percent,
              currency: "$",
              chart: chart
            };
          }
          return item;
        });
      });

      // MER : Total sales (Shopify)/ ad Spent (google + facebook)
      // const responseSales = await apiGetShopifySales({
      //   startDate: startDate,
      //   endDate: endDate
      // });

      // console.log(shopifySalesData.current);
      // console.log(_inRange);
      // const salesData = updateSalesData_spendData(
      //   { spendInRange: _inRange, spendBeforeRange: _beforeRange },
      //   // responseSales.data
      //   shopifySalesData.current
      // );
      // console.log(salesData)

      const mer_inRange = mergeArraysByDate_googleMeta_sales(_inRange, shopifySalesData.current.salesInRange);
      const mer_beforeRange = mergeArraysByDate_googleMeta_sales(_beforeRange, shopifySalesData.current.salesBeforeRange);

      // console.log("mer_inRange ??? ", mer_inRange);
      // console.log("mer_beforeRange ??? ", mer_beforeRange);

      let _merInRange: IChartData = {
        data: [],
        name: "Selected",
        color: "lightseagreen"
      };
      let _merBeforeRange: IChartData = {
        data: [],
        name: "Before",
        color: "lightgreen",
        isComparison: true
      };

      mer_inRange.forEach((item: any) => {
        _merInRange.data.push({
          key: item.date,
          value: calculateDivide(item.totalSales, item.spend)
        });
      });
      mer_beforeRange.forEach((item: any) => {
        _merBeforeRange.data.push({
          key: item.date,
          value: calculateDivide(item.totalSales, item.spend)
        });
      });
      const mer_chart = [_merInRange, _merBeforeRange];

      const totalSpends_mer_current = getTotalValue(mer_inRange, "spend");
      const totalSpends_mer_before = getTotalValue(mer_beforeRange, "spend");
      const totalSales_mer_current = getTotalValue(mer_inRange, "totalSales");
      const totalSales_mer_before = getTotalValue(mer_beforeRange, "totalSales");

      const mer_percent = calculatePercentageChange(
        calculateDivide(totalSales_mer_current, totalSpends_mer_current),
        calculateDivide(totalSales_mer_before, totalSpends_mer_before)
      );
      setData((prev: any) => {
        return prev.map((item: any) => {
          if (item.label === "MER") {
            return {
              label: item.label,
              value: totalSales_mer_current,
              percent: mer_percent,
              currency: "$",
              chart: mer_chart
            };
          }
          return item;
        });
      });

      // CPA : Cost per Conversion = all ads spends (google + facebook)  / orders
      // Cost per Conversion = google spends / google conversions + facebook spends / facebook purchases (conv)

      const cpa_merged_inRange = mergeSameArraysByDate(meta_spend_inRange, google_spend_inRange);
      const cpa_merged_beforeRange = mergeSameArraysByDate(meta_spend_beforeRange, google_spend_beforeRange);
      const cpa_inRange = calculateCPAValues(cpa_merged_inRange);
      const cpa_beforeRange = calculateCPAValues(cpa_merged_beforeRange);

      let _cpaInRange: IChartData = {
        data: [],
        name: "Selected",
        color: "lightseagreen"
      };
      let _cpaBeforeRange: IChartData = {
        data: [],
        name: "Before",
        color: "lightgreen",
        isComparison: true
      };
      cpa_inRange.forEach((item: any) => {
        _cpaInRange.data.push({
          key: item.date,
          value: item.value
        });
      });
      cpa_beforeRange.forEach((item: any) => {
        _cpaBeforeRange.data.push({
          key: item.date,
          value: item.value
        });
      });
      const cpa_chart = [_cpaInRange, _cpaBeforeRange];
      const totalCPA_current = getTotalValue(cpa_inRange, "value");
      const totalCPA_before = getTotalValue(cpa_beforeRange, "value");

      const cpa_percent = calculatePercentageChange(totalCPA_current, totalCPA_before);

      setData((prev: any) => {
        return prev.map((item: any) => {
          if (item.label === "CPA") {
            return {
              label: item.label,
              value: totalCPA_current,
              percent: cpa_percent,
              currency: "$",
              chart: cpa_chart
            };
          }
          return item;
        });
      });
    }

  };

  const calculateCPAValues = (combined: any) => {
    return combined.map((item: any) => {
      const googleValue = item.google_conv !== 0 ? item.google_spend / item.google_conv : 0;
      const metaValue = item.meta_conv !== 0 ? item.meta_spend / item.meta_conv : 0;
      const value = parseFloat((googleValue + metaValue).toFixed(2));

      return {
        date: item.date,
        value: value,
      };
    });
  };

  // Function to update salesData based on spendData and add missing dates
  const updateSalesData_spendData = (spendData: any, salesData: any) => {
    const updatedSalesData = { ...salesData };

    const updateRange = (spendRange: any[], salesRange: any[], key: string) => {
      const visitorDates = spendRange.map((v: any) => v.date);
      const spendMap = new Map(spendRange.map((v: any) => [v.date, v.spend]));

      // Create a map for sales data by date for quick lookup
      const salesMap = new Map(salesRange.map((sale: any) => [sale.date, sale]));

      // Generate updated sales range
      const updatedSalesRange = visitorDates.map((date: string) => {
        if (salesMap.has(date)) {
          const sale = salesMap.get(date);
          return {
            date: date,
            totalSales: sale.totalSales,
            orderCount: sale.orderCount,
            spend: spendMap.get(date) || 0
          };
        } else {
          return {
            date: date,
            totalSales: 0,
            orderCount: 0,
            spend: spendMap.get(date) || 0
          };
        }
      });

      // Sort the updated sales data by date
      updatedSalesRange.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());

      updatedSalesData[key] = updatedSalesRange;
    };

    updateRange(spendData.spendInRange, salesData.salesInRange, "salesInRange");
    updateRange(spendData.spendBeforeRange, salesData.salesBeforeRange, "salesBeforeRange");

    return updatedSalesData;
  };

  // Blended ROAS
  // (Google Conv. Value + Facebook Conv) / (Google Spent + Facebook Amount Spent) on home page
  const getBlendedROAS = async() => {
    if (metaKpiData.current && googleKpiData.current) {
      const meta_kpi_inRange = metaKpiData.current.dataInRange;
      const meta_kpi_beforeRange = metaKpiData.current.dataBeforeRange;
      const google_kpi_inRange = googleKpiData.current.dataInRange;
      const google_kpi_beforeRange = googleKpiData.current.dataBeforeRange;

      const _inRange = mergeAndConsolidateArrays(meta_kpi_inRange, google_kpi_inRange);
      const _beforeRange = mergeAndConsolidateArrays(meta_kpi_beforeRange, google_kpi_beforeRange);

      const totalRoas_current = parseFloat(((getTotalValue(_inRange, "conv")) / getTotalValue(_inRange, "spend")).toFixed(2));
      const totalRoas_before = parseFloat(((getTotalValue(_beforeRange, "conv")) / getTotalValue(_beforeRange, "spend")).toFixed(2));

      const percent = calculatePercentageChange(totalRoas_current, totalRoas_before);
      let inRange: IChartData = {
        data: [],
        name: "Selected",
        color: "lightseagreen"
      };
      let beforeRange: IChartData = {
        data: [],
        name: "Before",
        color: "lightgreen",
        isComparison: true
      };
      _inRange.forEach((item: any) => {
        inRange.data.push({ key: item.date, value: parseFloat((item.conv / item.spend).toFixed(2)) });
      });
      _beforeRange.forEach((item: any) => {
        beforeRange.data.push({ key: item.date, value: parseFloat((item.conv / item.spend).toFixed(2)) });
      });

      const chart = [inRange, beforeRange];

      setData((prev: any) => {
        return prev.map((item: any) => {
          if (item.label === "Blended ROAS") {
            return {
              label: item.label,
              value: totalRoas_current,
              percent: percent,
              currency: "$",
              chart: chart
            };
          }
          return item;
        });
      });
    }
  }

  // UI Part
  const renderBody = () => {
    return (
      <div className="relative w-full h-full">
        <div className="mx-auto max-w-4xl relative h-full text-black flex flex-col justify-between px-2 pt-10">
          <Carousel
            selectedItem={currentIndex}
            axis="vertical"
            showThumbs={false}
            infiniteLoop={false}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            swipeable={false}
            onChange={(index) => setCurrentIndex(index)}
          >

            <CarouselItem>
              <div id="date-btn" className="w-full flex justify-between sm:items-center items-start mb-5">
                <div className="flex gap-3 sm:flex-row flex-col">
                  <DatePicker onSelect={onSelectDateRange} inputRange={dateRange}/>
                  <CompareDateRange dateRange={selectedDateRange!}/>
                  <ChannelSelect channelType={Channel.AllChannels} />
                </div>
                <Header />
              </div>
              <Scrollbars className="flex-1">
                <div className="w-full grid lg:grid-cols-6 md:grid-cols-4 grid-cols-3 gap-2.5">
                  {data.map((item: any, index: number) => (
                    <CustomCard
                      key={index}
                      label={item.label}
                      value={item.value}
                      percent={item.percent}
                      isShowPercent={item.isShowPercent}
                      selected={selectedKpi == index}
                      currency={item.currency}
                      isLoading={isLoading}
                      onChangeHandler={() => setSelectedKpi(index)}
                    />
                  ))}
                </div>
                <div id="chartOne" className="w-full mt-5 mb-1">
                  <PolarisVizLineChart data={data[selectedKpi]} />
                </div>
              </Scrollbars>
            </CarouselItem>
            <CarouselItem>
              <PromptLibraryPage channelType={Channel.AllChannels} isLibraryPage={currentIndex == 1}/>
            </CarouselItem>
            <CarouselItem>
              <PromptResponsePage />
            </CarouselItem>
          </Carousel>
          <PromptChannel channelType={Channel.AllChannels} isLibraryPage={currentIndex == 1} onViewMore={goToPromptLibrary} onSend={goToPromptResponse} />
        </div>
        {currentIndex > 0 && <div className="absolute top-0 h-9 w-full pt-1 cursor-pointer text-center" style={{ backgroundColor: `rgba(0,0,0,0.25)` }} onClick={goToPrevious}>▼ {data[selectedKpi].label}
        </div>}
      </div>
    );
  };

  return <>{renderBody()}</>;
};
export default HomePage;