import React from "react";
import SideProMenu from "@/components/App/SideProMenu";
import { IconButton } from "@material-tailwind/react";
import { RxHamburgerMenu } from "react-icons/rx";
import { useMediaQuery } from "react-responsive";

export function AppLayout({ children }: React.PropsWithChildren): JSX.Element {
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const isDesktop = useMediaQuery({ minWidth: 767 })

  const hamburgerMenu = () => {
    return (
      <div className="absolute z-20">
        <IconButton
          placeholder
          variant="text"
          onClick={() => {
            setToggled(true);
          }}
        >
          <RxHamburgerMenu size={18} />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      <div className="h-screen flex bg-gray-50">
        <SideProMenu onSetCollapsed={setCollapsed} onToggled={toggled} onSetToggled={setToggled}/>
        <div
          id="main-content"
          className="w-full h-screen relative"
          // style={{paddingLeft: collapsed ? '0px' : '250px'}}
        >
          {!isDesktop && hamburgerMenu()}
          {children}
        </div>
      </div>
    </>
  );
}
