import CustomCard from "@/components/UI/CustomCard";
import { RootState } from "@/redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import appAction from "@/redux/app/action";
import { useEffect, useState } from "react";
import DatePicker from "@/components/UI/DatePicker";
import { PolarisVizLineChart } from "@/components/UI/Chart";
import { findKpiByRange, getDate, toISODateString, updateKpiData } from "@/shared/utils/utils";
import { IChartData, IRestApi } from "@/types/props";
import { Channel } from "@/types/enum";
import PromptChannel from "@/components/App/PromptChannel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import PromptLibraryPage from "@/pages/Prompt/Library";
import PromptResponsePage from "@/pages/Prompt/Response";
import ChannelSelect from "@/components/UI/ChannelSelect";
import Scrollbars from "react-custom-scrollbars-2";
import Header from "@/components/App/Header";
import { apiGetGoogleKpisData } from "@/shared/api/google";
import { calculatePercentageChange, getTotalValue } from "@/shared/utils/shopify";
import { DateRange, IKPI } from "@/types/basic";
import { useNavigate } from "react-router-dom";
import CompareDateRange from "@/components/UI/CompareDateRange";
import CarouselItem from "@/components/UI/CarouselItem";

const GooglePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, dateRange, isResponsePage, googleKpiData } = useSelector((state: RootState) => state.app);

  const [data, setData] = useState<IRestApi[]>([
    { label: "Total Ad Spend", percent: 0, value: 0, currency: "$", chart: [] },
    { label: "Total Conv. Value", percent: 0, value: 0, currency: "", chart: [] },
    { label: "ROAS", percent: 0, value: 0, currency: "$", chart: [] },
    { label: "CPA", percent: 0, value: 0, currency: "$", chart: [] }
  ]);

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedKpi, setSelectedKpi] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const onSelectDateRange = async (range: DateRange) => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    setSelectedDateRange(range);

    dispatch(appAction.onUpdateDateRange(range));

    setIsLoading(true);

    const kpiData = findKpiByRange(googleKpiData, range);
    if(kpiData) {
      setData(kpiData.data);
      setIsLoading(false);
      return;
    }

    await getKipsData(range);
    setIsLoading(false);
  };

  const goToPromptLibrary = () => {
    setCurrentIndex(1);
  };

  const goToPromptResponse = () => {
    setCurrentIndex(2);
  };

  const goToPrevious = () => {
    // setCurrentIndex((prevIndex) => (prevIndex - 1 + 3) % 3); // Assuming there are 3 slides
    setCurrentIndex(0);
  };

  const getKipsData = async (range: DateRange) => {
    const res = await apiGetGoogleKpisData(range);
    if(res?.success) {
      const _inRange = res.data.dataInRange;
      const _beforeRange = res.data.dataBeforeRange;

      const spend_current = getTotalValue(_inRange, "spend");
      const spend_before = getTotalValue(_beforeRange, "spend");
      const spend_percent = calculatePercentageChange(spend_current, spend_before);

      const conv_current = getTotalValue(_inRange, "conv");
      const conv_before = getTotalValue(_beforeRange, "conv");
      const conv_percent = calculatePercentageChange(conv_current, conv_before);

      const roas_current = getTotalValue(_inRange, "roas");
      const roas_before = getTotalValue(_beforeRange, "roas");
      const roas_percent = calculatePercentageChange(roas_current, roas_before);

      const cpa_current = getTotalValue(_inRange, "cpa");
      const cpa_before = getTotalValue(_beforeRange, "cpa");
      const cpa_percent = calculatePercentageChange(cpa_current, cpa_before);

      let inRange_spend: IChartData = {data: [], name: "Selected", color: "lightseagreen"};
      let beforeRange_spend: IChartData = {data: [], name: "Before", color: "lightgreen", isComparison: true};
      _inRange.forEach((item: any) => {
        inRange_spend.data.push({ key: item.date, value: item.spend });
      });
      _beforeRange.forEach((item: any) => {
        beforeRange_spend.data.push({ key: item.date, value: item.spend });
      });
      const spend_chart = [inRange_spend, beforeRange_spend];

      let inRange_conv: IChartData = {data: [], name: "Selected", color: "lightseagreen"};
      let beforeRange_conv: IChartData = {data: [], name: "Before", color: "lightgreen", isComparison: true};
      _inRange.forEach((item: any) => {
        inRange_conv.data.push({ key: item.date, value: item.conv });
      });
      _beforeRange.forEach((item: any) => {
        beforeRange_conv.data.push({ key: item.date, value: item.conv });
      });
      const conv_chart = [inRange_conv, beforeRange_conv];

      let inRange_roas: IChartData = {data: [], name: "Selected", color: "lightseagreen"};
      let beforeRange_roas: IChartData = {data: [], name: "Before", color: "lightgreen", isComparison: true};
      _inRange.forEach((item: any) => {
        inRange_roas.data.push({ key: item.date, value: item.roas });
      });
      _beforeRange.forEach((item: any) => {
        beforeRange_roas.data.push({ key: item.date, value: item.roas });
      });
      const roas_chart = [inRange_roas, beforeRange_roas];

      let inRange_cpa: IChartData = {data: [], name: "Selected", color: "lightseagreen"};
      let beforeRange_cpa: IChartData = {data: [], name: "Before", color: "lightgreen", isComparison: true};
      _inRange.forEach((item: any) => {
        inRange_cpa.data.push({ key: item.date, value: item.cpa });
      });
      _beforeRange.forEach((item: any) => {
        beforeRange_cpa.data.push({ key: item.date, value: item.cpa });
      });
      const cpa_chart = [inRange_cpa, beforeRange_cpa];

      const newData = [
        { label: "Total Ad Spend", percent: spend_percent, value: spend_current, currency: "$", chart: spend_chart },
        { label: "Total Conv. Value", percent: conv_percent, value: conv_current, currency: "", chart: conv_chart },
        { label: "ROAS", percent: roas_percent, value: roas_current, currency: "$", chart: roas_chart },
        { label: "CPA", percent: cpa_percent, value: cpa_current, currency: "$", chart: cpa_chart }
      ];
      setData(newData);

      const newKpiData: IKPI = {
        range: range,
        data: newData,
        createdAt: new Date()
      }
      // console.log(newKpiData);
      const updatedKpiData = updateKpiData(googleKpiData, newKpiData);
      dispatch(appAction.onUpdateGoogleKpiData(updatedKpiData));
    }
  }

  useEffect(() => {
    if (isResponsePage) {
      setCurrentIndex(2);
      dispatch(appAction.onUpdateResponsePage(false));
    }
  }, [isResponsePage]);

  // UI Part
  const renderBody = () => {
    return (
      <div className="relative w-full h-full">
        <div className="mx-auto max-w-4xl relative h-full text-black flex flex-col justify-between px-2 pt-10">
          <Carousel
            selectedItem={currentIndex}
            axis="vertical"
            showThumbs={false}
            infiniteLoop={false}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            swipeable={false}
            onChange={(index) => setCurrentIndex(index)}
          >
            <CarouselItem>
              <div id="date-btn" className="w-full flex justify-between sm:items-center items-start mb-5">
                <div className="flex gap-3 sm:flex-row flex-col">
                  <DatePicker onSelect={onSelectDateRange} inputRange={dateRange} />
                  <CompareDateRange dateRange={selectedDateRange!}/>
                  <ChannelSelect channelType={Channel.GoogleAds} />
                </div>
                <Header />
              </div>
              <Scrollbars className="flex-1">
                <div className="w-full grid lg:grid-cols-4 grid-cols-2 gap-2.5">
                  {data.map((item: any, index: number) => (
                    <CustomCard
                      key={index}
                      label={item.label}
                      value={item.value}
                      percent={item.percent}
                      isShowPercent={item.isShowPercent}
                      selected={selectedKpi == index}
                      currency={item.currency}
                      isLoading={isLoading}
                      onChangeHandler={() => setSelectedKpi(index)}
                    />
                  ))}
                </div>
                <div id="chartOne" className="w-full mt-5 mb-1">
                  <PolarisVizLineChart data={data[selectedKpi]} />
                </div>
              </Scrollbars>
            </CarouselItem>
            <CarouselItem>
              <PromptLibraryPage channelType={Channel.GoogleAds} isLibraryPage={currentIndex == 1}/>
            </CarouselItem>
            <CarouselItem>
              <PromptResponsePage />
            </CarouselItem>
          </Carousel>
          <PromptChannel channelType={Channel.GoogleAds} isLibraryPage={currentIndex == 1} onViewMore={goToPromptLibrary} onSend={goToPromptResponse} />
        </div>
        {currentIndex > 0 && <div className="absolute top-0 h-9 w-full pt-1 cursor-pointer text-center" style={{ backgroundColor: `rgba(0,0,0,0.25)` }} onClick={goToPrevious}>▼ {data[selectedKpi].label}
        </div>}
      </div>
    );
  };

  return <>{renderBody()}</>;
};
export default GooglePage;
