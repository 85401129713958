import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { RootState } from "@/redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import appAction from "@/redux/app/action";
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import ShopifyConnect from "./Shopify";
import FacebookConnect from "./Facebook";
import GoogleConnect from "./Google";
import { apiGetSocialConnects } from "@/shared/api/user";

const SocialConnects = () => {
  const { userId, shopify } = useSelector((state: RootState) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(["facebook", "google", "shopify"]);
  const [connectedFacebook, setConnectedFacebook] = useState(cookies.facebook === "success"? true : false);
  const [connectedGoogle, setConnectedGoogle] = useState(cookies.google === "success"? true : false);
  const [connectedShopify, setConnectedShopify] = useState(cookies.shopify === "success"? true : false);

  useEffect(() => {
    onGetConnects();
  }, []);

  useEffect(() => {
    if(connectedFacebook && connectedGoogle && connectedShopify && shopify?.shopName)
    {
      setTimeout(() => {
        Cookies.set("socialsConnected", "success");
        navigate("/");
      }, 1000);
    }
    else {
      Cookies.set("socialsConnected", "fail");
    }
  }, [connectedFacebook, connectedGoogle, connectedShopify, shopify])

  const onGetConnects = async () => {
    if(userId === undefined) {
      navigate("/login");
      return;
    }
    const result = await apiGetSocialConnects();
    if(result?.success) {
      if(result.socials?.facebook.status) setCookie("facebook", "success"); else setCookie("facebook", "fail");
      if(result.socials?.google.status) setCookie("google", "success"); else setCookie("google", "fail");
      if(result.socials?.shopify.status) setCookie("shopify", "success"); else setCookie("shopify", "fail");
      setConnectedFacebook(result.socials?.facebook.status);
      setConnectedGoogle(result.socials?.google.status);
      setConnectedShopify(result.socials?.shopify.status);
      if(result.socials?.shopify.status)
      {
        dispatch(appAction.loadUserShoipfyInfo({
          shopName: result.socials?.shopify.id
        }));
      }
    }
  }

  return(
    <main className="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl py-2 ">
      <div className="pt-6 px-4 flex items-center justify-center">
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
          <div className="flex items-center justify-between mb-4">
            <div className="flex-shrink-0">
              <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                Available Integrations
              </span>
            </div>
          </div>
          <div id="social-connects">
            <ul role="list" className="divide-y divide-gray-200">
              <FacebookConnect isConnected={connectedFacebook} setConnected={setConnectedFacebook} />
              <GoogleConnect isConnected={connectedGoogle} setConnected={setConnectedGoogle} />
              <ShopifyConnect isConnected={connectedShopify} setConnected={setConnectedShopify} />
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SocialConnects;
