import CustomInput from '@/components/UI/CustomInput';
import { Avatar, Button, Select, Tab, Tabs, TabsHeader, Option } from '@material-tailwind/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { RootState } from "@/redux/interfaces";
import appAction from "@/redux/app/action";
import { useDispatch, useSelector } from "react-redux";
import {
  client_type_options,
  customer_role_options
} from "@/shared/utils/constants";
import { apiPostUserRoles } from '@/shared/api/user';
import { showToast } from '@/components/UI/PrimaryToast';

export const Roles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.app);
  const [clientType, setClientType] = useState(userData?.clientType || client_type_options[0].value);
  const [customerRole, setCustomerRole] = useState(userData?.customerRole || customer_role_options[0].value);

  const handleChangeRole = (value: any) => {
    setCustomerRole(value);
  };

  const handleTabClientType = (value: any) => {
    setClientType(value);
  };

  const updateRoles = async () => {
    if(!!clientType && !!customerRole) {
      const res = await apiPostUserRoles({
        clientType,
        customerRole
      });

      if(res?.success) {
        console.log(res.data);
        dispatch(appAction.loadUserInfoSuccess(res.data));
        showToast("success", "Profile updated successfully");
      }
      else {
        console.log('Profile update failed!');
      }
    }
  }

  return (
    <>
      <h1 className='text-xl font-semibold border-b border-blue-gray-100 pb-3 mb-6'>Roles</h1>
      <div className=''>
        <p className="text-black opacity-60 text-sm font-semibold mb-2">
          Account type
        </p>
        <Tabs className="sm:w-1/3 w-[200px] min-h-9" value={clientType}>
          <TabsHeader placeholder>
            {client_type_options.map(({ value }, index) => (
              <Tab placeholder className='text-sm py-2' key={index} value={value} onClick={() => handleTabClientType(value)}>
                {value}
              </Tab>
            ))}
          </TabsHeader>
        </Tabs>
        <p className="text-black opacity-60 text-sm font-semibold mb-2 mt-5">
          {t("customer_role")}
        </p>
        <div className="min-h-[18px] max-w-24 mb-4">
          <Select placeholder value={customerRole} onChange={handleChangeRole}
            className="border border-blue-gray-200 focus:border-blue-600"
            labelProps={{ className: "hidden" }}>
            {customer_role_options.map(({ value }, index) => (
              <Option key={index} value={value}>{value}</Option>
            ))}
          </Select>
        </div>
        <div className='mt-4'>
          <Button placeholder color="green" className='capitalize' onClick={updateRoles}>Update Roles</Button>
        </div>
      </div>
    </>
  )
}
