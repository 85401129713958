import {
  apiGetGoogleAdAccount,
  apiGetGoogleAdInfo,
  apiGetGoogleCustomers,
} from "@/shared/api/user";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Checkbox,
  Input
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { showToast } from "@/components/UI/PrimaryToast";
import { IAdAccount } from "@/types/props";
import { FcGoogle } from "react-icons/fc";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
interface Props {
  isConnected: boolean;
  setConnected: (connected: boolean) => void;
}

const GoogleConnect = ({ isConnected, setConnected }: Props) => {
  const query = useQuery();
  const googleId = query.get("google");
  const [cookies, setCookie] = useCookies(["google"]);
  const [openGoogle, setOpenGoogle] = useState(false);
  const [selectedGoogleAd, setSelectedGoogleAd] = useState<IAdAccount | null>(null);
  const [adGoogleAccounts, setGoogleAdAccounts] = useState<Array<IAdAccount>>([]);
  const [openGoogleCustomers, setOpenGoogleCustomers] = useState(false);
  const [selectedGoogleCustomer, setSelectedGoogleCustomer] = useState<IAdAccount | null>(null);
  const [googleCustomers, setGoogleCustomers] = useState<Array<IAdAccount>>([]);
  const [connectedGoogle, setConnectedGoogle] = useState(isConnected);
  const [openModalAnalytic, setOpenModalAnalytic] = useState(false);
  const [analyticViewerId, setAnalyticViewerId] = useState("");

  useEffect(() => {
    setConnectedGoogle(isConnected);
  }, [isConnected]);

  useEffect(() => {
    if (googleId) {
      console.log(googleId);
      getGoogleCustomers(googleId);
    }
  }, [googleId]);

  const onOpenModalGoogleCustomers = () => setOpenGoogleCustomers(true);
  const onCloseModalGoogleCustomers = () => setOpenGoogleCustomers(false);
  const onOpenModalGoogle = () => setOpenGoogle(true);
  const onCloseModalGoogle = () => setOpenGoogle(false);
  const onOpenModalAnalytic = () => setOpenModalAnalytic(true);
  const onCloseModalAnalytic = () => setOpenModalAnalytic(false);

  const onSelectGoogleCustomer = (index: number) => {
    setSelectedGoogleCustomer(googleCustomers[index]);
  };

  const onSelectGoogleAdAccount = (index: number) => {
    setSelectedGoogleAd(adGoogleAccounts[index]);
  };

  const getGoogleCustomers = async (googleId: string) => {
    const res = await apiGetGoogleCustomers({
      googleId,
    });

    if (res?.success) {
      setGoogleCustomers(res?.customers);
      if (res?.customers.length > 0) {
        setSelectedGoogleCustomer(res?.customers[0]);
        if (res?.customers.length > 1) onOpenModalGoogleCustomers();
        else {
          await getGoogleAdAccounts(res?.customers[0]!.id);
        }
      }
    } else {
      showToast("error", "Create a new account, or add the Google account to an existing Ads account.")
    }
  };

  const onConfirmGoogleCustomer = async () => {
    onCloseModalGoogleCustomers();
    getGoogleAdAccounts(selectedGoogleCustomer!.id);
  };

  const getGoogleAdAccounts = async (customerId: string) => {
    const res = await apiGetGoogleAdAccount({
      googleId: googleId!,
      customerId: customerId
    });
    if (res?.success) {
      setGoogleAdAccounts(res?.adAccounts);
      if (res?.adAccounts.length > 0) {
        setSelectedGoogleAd(res?.adAccounts[0]);
        if (res?.adAccounts.length > 1) onOpenModalGoogle();
        else {
          onOpenModalAnalytic();
        }
      }
    } else {
      console.log(res?.error ?? res);
    }
  };

  const onConfirmGoogleAdAccount = async () => {
    onCloseModalGoogle();
    onOpenModalAnalytic();
  };

  const getGoogleAdInfo = async () => {
    const result = await apiGetGoogleAdInfo({
      googleId: googleId!,
      adAccountId: selectedGoogleAd!.id,
      analyticViewerId: analyticViewerId
    });
    if (result?.success) {
      setCookie("google", "success");
      showToast("success", "Successfully connected to Google Ad Account");
      setConnectedGoogle(true);
      setConnected(true);
    } else {
      console.log(result?.error ?? result);
    }
  }

  const onConfirmAnalyticViewer = async () => {
    if(analyticViewerId === "") {
      showToast("error", "Please enter Google Analytic Viewer ID");
      return;
    }
    onCloseModalAnalytic();
    getGoogleAdInfo();
  }

  const onConnectGoogle = () => {
    window.location.href =
      process.env.REACT_APP_SERVER_URL + `/user/google/oauth_url`;
  };

  return (
    <>
      <li className="py-3 sm:py-4">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            <FcGoogle size={40} />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-base font-medium text-gray-900 truncate">
              Google Ads & Youtube
            </p>
            <p className="text-sm text-gray-500 truncate">
              {connectedGoogle ? "Connected" : "No account connected"}
            </p>
          </div>
          <Button
            placeholder
            color="green"
            variant={connectedGoogle ? "outlined" : "filled"}
            size={"sm"}
            onClick={() => {
              !connectedGoogle && onConnectGoogle();
            }}
          >
            {connectedGoogle ? "connected" : "connect"}
          </Button>
        </div>
      </li>

      <Dialog
        placeholder
        open={openGoogleCustomers}
        handler={onOpenModalGoogleCustomers}
      >
        <DialogHeader placeholder>Select Manager Account</DialogHeader>
        <DialogBody placeholder>
          {googleCustomers.map((customer, index) => (
            <div key={index}>
              <Checkbox
                crossOrigin
                label={`${customer.name}[${customer.id}]`}
                defaultChecked={index === 0}
                checked={customer === selectedGoogleCustomer}
                onChange={() => onSelectGoogleCustomer(index)}
              />
            </div>
          ))}
        </DialogBody>
        <DialogFooter placeholder>
          <Button
            placeholder
            variant="gradient"
            color="green"
            onClick={onConfirmGoogleCustomer}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>

      <Dialog placeholder open={openGoogle} handler={onOpenModalGoogle}>
        <DialogHeader placeholder>Select Ad Account</DialogHeader>
        <DialogBody placeholder>
          {adGoogleAccounts.map((adAccount, index) => (
            <div key={index}>
              <Checkbox
                crossOrigin
                label={adAccount.name}
                defaultChecked={index === 0}
                checked={adAccount === selectedGoogleAd}
                onChange={() => onSelectGoogleAdAccount(index)}
              />
            </div>
          ))}
        </DialogBody>
        <DialogFooter placeholder>
          <Button
            placeholder
            variant="gradient"
            color="green"
            onClick={onConfirmGoogleAdAccount}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>

      <Dialog placeholder open={openModalAnalytic} handler={onOpenModalAnalytic}>
        <DialogHeader placeholder>Google Analytic Viewer ID</DialogHeader>
        <DialogBody placeholder>
          <div className="w-72">
            <Input crossOrigin label="Viewer ID" value={analyticViewerId} onChange={(e) => {setAnalyticViewerId(e.target.value);}} />
          </div>
        </DialogBody>
        <DialogFooter placeholder>
          <Button
            placeholder
            variant="gradient"
            color="green"
            onClick={onConfirmAnalyticViewer}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default GoogleConnect;
