import HttpClient from "./apiClient";

export async function apiGetMetaKpisData(params: {
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/facebook/api/ads/kpi", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}