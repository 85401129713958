import { DateRange, IKPI } from "@/types/basic";

const actions = {
  SUCCESS_LOGIN: "SUCCESS_LOGIN",
  SUCCESS_LOGOUT: "SUCCESS_LOGOUT",
  LOAD_USER_INFO: "LOAD_USER_INFO",
  LOAD_USER_INFO_SUCCESS: "LOAD_USER_INFO_SUCCESS",
  LOAD_USER_INFO_FAILED: "LOAD_USER_INFO_FAILED",
  LOAD_USER_SHOPIFY_INFO: "LOAD_USER_SHOPIFY_INFO",
  UPDATE_DATE_RANGE: "UPDATE_DATE_RANGE",
  UPDATE_PROMPT_LIBRARY_DATA: "UPDATE_PROMPT_LIBRARY_DATA",
  UPDATE_PROMPT_REQUEST_DATA: "UPDATE_PROMPT_REQUEST_DATA",
  UPDATE_PROMPT_RESPONSE_DATA: "UPDATE_PROMPT_RESPONSE_DATA",
  UPDATE_IS_RESPONSE_PAGE: "UPDATE_IS_RESPONSE_PAGE",
  DELETE_PROMPT: "DELETE_PROMPT",
  UPDATE_SHOPIFY_DATA: "UPDATE_SHOPIFY_DATA",
  UPDATE_GOOGLE_DATA: "UPDATE_GOOGLE_DATA",
  UPDATE_META_DATA: "UPDATE_META_DATA",

  successLogin: (data: any) => ({
    payload: data,
    type: actions.SUCCESS_LOGIN,
  }),
  successLogout: () => ({
    type: actions.SUCCESS_LOGOUT,
  }),
  loadUserInfo: (userId: number) => ({
    type: actions.LOAD_USER_INFO,
    payload: userId,
  }),
  loadUserInfoSuccess: (data: any) => ({
    type: actions.LOAD_USER_INFO_SUCCESS,
    payload: data,
  }),
  loadUserInfoFailed: () => ({
    type: actions.LOAD_USER_INFO_FAILED,
  }),
  loadUserShoipfyInfo: (data: any) => ({
    type: actions.LOAD_USER_SHOPIFY_INFO,
    payload: data,
  }),
  onUpdateDateRange: (date: DateRange | undefined) => {
    return {
      type: actions.UPDATE_DATE_RANGE,
      payload: date,
    };
  },
  onUpdatePromptLibraryData: (promptLibraryData: any) => {
    return {
      type: actions.UPDATE_PROMPT_LIBRARY_DATA,
      payload: promptLibraryData,
    };
  },
  onUpdatePromptRequestData: (promptRequestData: any) => {
    return {
      type: actions.UPDATE_PROMPT_REQUEST_DATA,
      payload: promptRequestData,
    };
  },
  onUpdatePromptResponseData: (promptResponseData: any) => {
    return {
      type: actions.UPDATE_PROMPT_RESPONSE_DATA,
      payload: promptResponseData,
    };
  },
  onUpdateResponsePage: (isResponsePage: boolean) => {
    return {
      type: actions.UPDATE_IS_RESPONSE_PAGE,
      payload: isResponsePage,
    };
  },
  onDeletePrompt: (isDeletePrompt: boolean) => {
    return {
      type: actions.DELETE_PROMPT,
      payload: isDeletePrompt,
    };
  },
  onUpdateShopifyKpiData: (shopifyKpiData: IKPI[] | undefined) => {
    return {
      type: actions.UPDATE_SHOPIFY_DATA,
      payload: shopifyKpiData,
    };
  },
  onUpdateGoogleKpiData: (googleKpiData: IKPI[] | undefined) => {
    return {
      type: actions.UPDATE_GOOGLE_DATA,
      payload: googleKpiData,
    };
  },
  onUpdateMetaKpiData: (metaKpiData: IKPI[] | undefined) => {
    return {
      type: actions.UPDATE_META_DATA,
      payload: metaKpiData,
    };
  }
};

export default actions;
