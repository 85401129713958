import { DashboardPage, SettingsPage, HelpPage, HelpDetail, HomePage, MetaPage, GooglePage, ShopifyPage, EmailPage } from '@/pages';
import { EmptyLayout } from '@/layouts/EmptyLayout';
import { AppLayout } from '@/layouts/AppLayout';
import { SettingLayout } from '@/layouts/SettingLayoout';

export const privateRoutes = [
  {
    path: '/',
    element: <HomePage />,
    layout: AppLayout
  },
  {
    path: '/ads/meta',
    element: <MetaPage />,
    layout: AppLayout
  },
  {
    path: '/ads/google',
    element: <GooglePage />,
    layout: AppLayout
  },
  {
    path: '/ads/shopify',
    element: <ShopifyPage />,
    layout: AppLayout
  },
  {
    path: '/ads/email',
    element: <EmailPage />,
    layout: AppLayout
  },
  {
    path: '/dashboard',
    element: <DashboardPage />,
    layout: EmptyLayout
  },
  {
    path: '/settings',
    element: <SettingsPage />,
    layout: SettingLayout
  },
  {
    path: '/help',
    element: <HelpPage />,
    layout: SettingLayout
  },
  {
    path: '/help/:slug',
    element: <HelpDetail />,
    layout: SettingLayout
  }
];