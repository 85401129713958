import styled from "styled-components";

interface Props {
  label?: string;
  type: string;
  value: string;
  color?: string;
  onChangeHandler?: (e: any) => void;
}

export default function CustomInput({label, type, value, color, onChangeHandler}: Props) {
  return (
    <div className="relative w-full mb-3 ">
      <label htmlFor={label} className="text-black text-sm font-semibold">
        {label}
      </label>
      <StyledInput
        type={type}
        color={color}
        placeholder={label}
        value={value}
        required
        onChange={onChangeHandler}
        className="mt-1 w-full text-sm bg-transparent border rounded-lg border-blue-gray-200 py-2 px-5 focus:border-blue-600 focus:outline-none"
      />
    </div>
  );
}

const StyledInput = styled.input<{color?: string}>`
  &:focus {
    outline: none;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 500px ${({ color, theme }) => color? color == 'black' ? 'white' : 'black' : theme.backgroundColor}
      inset !important;
    box-shadow: 0 0 0 500px ${({ color, theme }) => color? color == 'black' ? '#eeeeee' : 'black' : theme.backgroundColor} inset !important;
    -webkit-text-fill-color: ${({ color, theme }) => color ? color : theme.textColor};
  }
  color: ${({ color, theme }) => color ? color : theme.textColor};
  border-width: 1px;
`;