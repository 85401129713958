import React, { useCallback, useEffect } from 'react'
import { Popover, PopoverHandler, PopoverContent, List, ListItem, Card } from "@material-tailwind/react";

interface Props {
  options: any;
  onChange: (value: string) => void;
}

function TextSelect({ options, onChange }: Props) {
  const [openPopover, setOpenPopover] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState('');

  useEffect(() => {
    setSelectedOption(options[0].label);
    onChange(options[0].label)
  }, [options]);

  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => setOpenPopover(false),
  };

  const onChangeOption = (option: string) => {
    setSelectedOption(option);
    onChange(option);
  }

  return (
    <div className='font-normal mx-1'>
      <Popover open={openPopover} handler={setOpenPopover} placement="top-start">
        <PopoverHandler {...triggers}>
          <p className='text-[13px] text-blue-500'>{selectedOption}</p>
        </PopoverHandler>
        <PopoverContent placeholder {...triggers} className="z-50 p-1.5 w-fit">
          <List placeholder className='my-0 p-0 !min-w-fit'>
            {options.map((option: any, index: number) => (
              <ListItem key={index} placeholder className='py-1 px-2 text-[13px]' onClick={() => {(onChangeOption(option.label))}}>{option.label}</ListItem>
            ))}
          </List>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default TextSelect