import HelpCard from '@/components/UI/HelpCard';
import SearchInput from '@/components/UI/SearchInput';
import { useInfiniteHelps } from '@/hooks/useInfiniteQueries';
import React, { useCallback, useEffect, useState } from 'react'
import Scrollbars from "react-custom-scrollbars-2";
import { IoCloseOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
import { Spinner } from '@material-tailwind/react';

function HelpPage() {
  const navigate = useNavigate();
  const [helpData, setHelpData] = useState<any>([]);
  const [query, setQuery] = useState<string>("");
  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteHelps(query, 10);

  const debouncedRefetch = useCallback(
    _.debounce(() => {
      refetch();
    }, 300),
    [refetch]
  );

  useEffect(() => {
    debouncedRefetch();
    return () => {
      debouncedRefetch.cancel();
    };
  }, [query, debouncedRefetch]);

  useEffect(() => {
    if (data) {
      const arrData = data
        ? data.pages.flatMap((page: any) => page?.data ?? [])
        : [];
      setHelpData(arrData);
    }
  }, [data]);

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      hasNextPage && !isFetchingNextPage && fetchNextPage();
    }
  };

  return (
    <div className='max-w-6xl mx-auto h-screen flex flex-col items-center text-black sm:p-6 p-2.5'>
      <div className='w-full flex flex-col items-center sm:pb-10 pb-5 sm:pt-0 pt-2 relative'>
        <h1 className='text-2xl font-semibold mb-6'>{"We're here to help"}</h1>
        <div className='md:w-2/5 sm:w-3/4 w-11/12'>
          <SearchInput
            label={'Search'}
            type="text"
            value={query}
            color="black"
            shadow={true}
            onChangeHandler={(e) => {
              setQuery(e.target.value);
            }}
          />
        </div>
        <div className='absolute right-0'>
          <IoCloseOutline size={26} className='cursor-pointer' onClick={() => navigate('/')} />
        </div>
      </div>
      <Scrollbars className='flex-1 w-full'
        style={{ paddingBottom: 50 }}
        onScroll={handleScroll}>
        <div className='w-full grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 px-3 py-2'>
          {helpData.length > 0 && helpData.map((item: any, index: number) => (
            <HelpCard key={index} data={item} />
          ))}
        </div>
        {isFetchingNextPage && <Spinner className='mx-auto' />}
      </Scrollbars>
      <div className='h-6'></div>
    </div>
  )
}

export default HelpPage