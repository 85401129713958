export enum Direction {
  Horizontal = "horizontal",
  Vertical = "vertical"
}

export enum Channel {
  AllChannels = "all",
  MetaAds = "meta",
  GoogleAds = "google",
  Shopify = "shopify",
  Email = "email"
}