import {
  apiGetFacebookAdAccount,
  apiGetFacebookAdInfo
} from "@/shared/api/user";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Checkbox,
  Input
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { FaFacebook } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { showToast } from "@/components/UI/PrimaryToast";
import { IAdAccount } from "@/types/props";

interface Props {
  fbId?: string;
}

function Meta({fbId=''}: Props) {
  const [facebookId, setFacebookId] = useState(fbId || "");
  const [cookies, setCookie] = useCookies(["facebook"]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPixel, setOpenModalPixel] = useState(false);
  const [selectedFBAd, setSelectedFBAd] = useState<IAdAccount | null>(null);
  const [adAccounts, setAdAccounts] = useState<Array<IAdAccount>>([]);
  const [connectedFacebook, setConnectedFacebook] = useState(false);
  const [pixelId, setPixelId] = useState("");

  useEffect(() => {
    if (facebookId) {
      console.log(facebookId);
      facebookAdAccounts(facebookId);
    }
  }, [facebookId]);

  const onConnectFacebook = async () => {
    window.location.href =
      process.env.REACT_APP_SERVER_URL + `/user/facebook/update/oauth_url`;
  };

  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);

  const onOpenModalPixel = () => setOpenModalPixel(true);
  const onCloseModalPixel = () => setOpenModalPixel(false);

  const facebookAdAccounts = async (facebookId: string) => {
    const res = await apiGetFacebookAdAccount({
      facebookId,
    });
    if (res?.success) {
      setAdAccounts(res?.adAccounts);
      if (res?.adAccounts.length > 0) {
        setSelectedFBAd(res?.adAccounts[0]);
        if (res?.adAccounts.length > 1) onOpenModal();
        else {
          onOpenModalPixel();
          setSelectedFBAd(res?.adAccounts[0]);
        }
      }
    } else {
      console.log(res?.error ?? res);
    }
  };

  const onSelectFacebookAdAccount = (index: number) => {
    setSelectedFBAd(adAccounts[index]);
  };

  const onConfirmFacebookAdAccount = async () => {
    onCloseModal();
    onOpenModalPixel();
  };

  const onConfirmPixelId = async () => {
    if(pixelId === "") {
      showToast("error", "Please enter Meta Pixel ID");
      return;
    }
    onCloseModalPixel();
    connectMeta();
  }

  const connectMeta = async () => {
    const result = await apiGetFacebookAdInfo({
      facebookId: facebookId!,
      adAccountId: selectedFBAd!.id,
      pixelId: pixelId
    });
    if (result?.success) {
      setCookie("facebook", "success");
      showToast("success", "Successfully connected to Facebook Ad Account");
      setConnectedFacebook(true);
    } else {
      console.log(result?.error ?? result);
    }
  }


  return (
    <>
      <h1 className='text-xl font-semibold border-b border-blue-gray-100 pb-3 mb-6'>
        {cookies.facebook === 'success' ? "Connect meta with a new account" : "Connect meta" }
      </h1>
      <Button placeholder color="green" className='capitalize' onClick={onConnectFacebook}>Connect</Button>

      <Dialog placeholder open={openModal} handler={onOpenModal}>
        <DialogHeader placeholder>Select Ad Account</DialogHeader>
        <DialogBody placeholder>
          {adAccounts.map((adAccount, index) => (
            <div key={index}>
              <Checkbox
                crossOrigin
                label={adAccount?.name}
                defaultChecked={index === 0}
                checked={adAccount.id === selectedFBAd?.id}
                onChange={() => onSelectFacebookAdAccount(index)}
              />
            </div>
          ))}
        </DialogBody>
        <DialogFooter placeholder>
          <Button
            placeholder
            variant="gradient"
            color="green"
            onClick={onConfirmFacebookAdAccount}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>

      <Dialog placeholder open={openModalPixel} handler={onOpenModalPixel}>
        <DialogHeader placeholder>Meta Pixel Id</DialogHeader>
        <DialogBody placeholder>
          <div className="w-72">
            <Input crossOrigin label="Meta Pixel ID" value={pixelId} onChange={(e) => {setPixelId(e.target.value);}} />
          </div>
        </DialogBody>
        <DialogFooter placeholder>
          <Button
            placeholder
            variant="gradient"
            color="green"
            onClick={onConfirmPixelId}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default Meta