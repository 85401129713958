import {
  apiGetFacebookAdAccount,
  apiGetFacebookAdInfo
} from "@/shared/api/user";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Checkbox,
  Input
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { FaFacebook } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { showToast } from "@/components/UI/PrimaryToast";
import { IAdAccount } from "@/types/props";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
interface Props {
  isConnected: boolean;
  setConnected: (connected: boolean) => void;
}

const FacebookConnect = ({ isConnected, setConnected }: Props) => {
  const query = useQuery();
  const facebookId = query.get("facebook");
  const [cookies, setCookie] = useCookies(["facebook"]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPixel, setOpenModalPixel] = useState(false);
  const [selectedFBAd, setSelectedFBAd] = useState<IAdAccount | null>(null);
  const [adAccounts, setAdAccounts] = useState<Array<IAdAccount>>([]);
  const [connectedFacebook, setConnectedFacebook] = useState(isConnected);
  const [pixelId, setPixelId] = useState("");

  useEffect(() => {
    setConnectedFacebook(isConnected);
  }, [isConnected]);

  useEffect(() => {
    if (facebookId) {
      console.log(facebookId);
      facebookAdAccounts(facebookId);
    }
  }, [facebookId]);

  const onConnectFacebook = async () => {
    window.location.href =
      process.env.REACT_APP_SERVER_URL + `/user/facebook/oauth_url`;
  };

  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);

  const onOpenModalPixel = () => setOpenModalPixel(true);
  const onCloseModalPixel = () => setOpenModalPixel(false);

  const facebookAdAccounts = async (facebookId: string) => {
    const res = await apiGetFacebookAdAccount({
      facebookId,
    });
    if (res?.success) {
      setAdAccounts(res?.adAccounts);
      if (res?.adAccounts.length > 0) {
        setSelectedFBAd(res?.adAccounts[0]);
        if (res?.adAccounts.length > 1) onOpenModal();
        else {
          onOpenModalPixel();
          setSelectedFBAd(res?.adAccounts[0]);
          // const result = await apiGetFacebookAdInfo({
          //   facebookId,
          //   userId: userId!,
          //   adAccountId: res?.adAccounts[0].id
          // });
          // if (result?.success) {
          //   setCookie("facebook", "success");
          //   showToast("success", "Successfully connected to Facebook Ad Account");
          //   setConnectedFacebook(true);
          //   setConnected(true);
          // } else {
          //   console.log(result?.error ?? result);
          // }
        }
      }
    } else {
      console.log(res?.error ?? res);
    }
  };

  const onSelectFacebookAdAccount = (index: number) => {
    setSelectedFBAd(adAccounts[index]);
  };

  const onConfirmFacebookAdAccount = async () => {
    onCloseModal();
    onOpenModalPixel();
  };

  const onConfirmPixelId = async () => {
    if(pixelId === "") {
      showToast("error", "Please enter Meta Pixel ID");
      return;
    }
    onCloseModalPixel();
    connectMeta();
  }

  const connectMeta = async () => {
    const result = await apiGetFacebookAdInfo({
      facebookId: facebookId!,
      adAccountId: selectedFBAd!.id,
      pixelId: pixelId
    });
    if (result?.success) {
      setCookie("facebook", "success");
      showToast("success", "Successfully connected to Facebook Ad Account");
      setConnectedFacebook(true);
      setConnected(true);
    } else {
      console.log(result?.error ?? result);
    }
  }

  return (
    <>
      <li className="py-3 sm:py-4">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            <FaFacebook color="#0082F6" size={40} />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-base font-medium text-gray-900 truncate">
              Facebook
            </p>
            <p className="text-sm text-gray-500 truncate">
              {connectedFacebook ? "Connected" : "No account connected"}
            </p>
          </div>
          <Button
            placeholder
            color="green"
            variant={connectedFacebook ? "outlined" : "filled"}
            size={"sm"}
            onClick={() => {
              !connectedFacebook && onConnectFacebook();
            }}
          >
            {connectedFacebook ? "connected" : "connect"}
          </Button>
        </div>
      </li>

      <Dialog placeholder open={openModal} handler={onOpenModal}>
        <DialogHeader placeholder>Select Ad Account</DialogHeader>
        <DialogBody placeholder>
          {adAccounts.map((adAccount, index) => (
            <div key={index}>
              <Checkbox
                crossOrigin
                label={adAccount?.name}
                defaultChecked={index === 0}
                checked={adAccount.id === selectedFBAd?.id}
                onChange={() => onSelectFacebookAdAccount(index)}
              />
            </div>
          ))}
        </DialogBody>
        <DialogFooter placeholder>
          <Button
            placeholder
            variant="gradient"
            color="green"
            onClick={onConfirmFacebookAdAccount}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>

      <Dialog placeholder open={openModalPixel} handler={onOpenModalPixel}>
        <DialogHeader placeholder>Meta Pixel Id</DialogHeader>
        <DialogBody placeholder>
          <div className="w-72">
            <Input crossOrigin label="Meta Pixel ID" value={pixelId} onChange={(e) => {setPixelId(e.target.value);}} />
          </div>
        </DialogBody>
        <DialogFooter placeholder>
          <Button
            placeholder
            variant="gradient"
            color="green"
            onClick={onConfirmPixelId}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default FacebookConnect;
