import Cookies from "js-cookie";

export const getToken = async () => {
  let token = Cookies.get("accessToken");
  let attempts = 0;

  while (!token && attempts < 3) {
    await new Promise((resolve) => setTimeout(resolve, 100));
    token = Cookies.get("accessToken");
    attempts++;
  }

  return token || "";
};

export const removeToken = async() => {
  // Cookies.set("accessToken", "");
  Cookies.remove("accessToken");
};
