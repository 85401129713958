import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Avatar,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { LuUser2 } from "react-icons/lu";
import { MdSwitchAccount } from "react-icons/md";
import { GoShieldLock } from "react-icons/go";
import { FaShopify } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { FaMeta } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { RootState } from "@/redux/interfaces";
import { useSelector } from "react-redux";

const LineIcon = () => (
  <div className="absolute top-1/4 -left-1.5 rounded-xl h-5 w-1 bg-blue-600"></div>
);

const ListItemComponent = ({text, icon, selected, onSelect}: any) => {
  return (
    <div className="relative" style={{ backgroundColor: selected ? '#eceff1':'transparent', borderRadius: selected ? '0.5rem' : '0' }} >
      <ListItem placeholder className="font-semibold text-sm p-2" onClick={onSelect}>
        <ListItemPrefix placeholder className="mr-2">{icon}</ListItemPrefix>
        {text}
      </ListItem>
      {selected && <LineIcon />}
    </div>
  );
}

const settings = [
  {
    category: 'Account',
    subcategory: [
      {
        title: "Profile",
        icon: <LuUser2 color="#778491" size={16}/>
      },
      {
        title: "Roles",
        icon: <MdSwitchAccount color="#778491" size={18}/>
      }
    ]
  },
  {
    category: 'Security',
    subcategory: [
      {
        title: "Change Password",
        icon: <GoShieldLock color="#778491" size={16}/>
      }
    ]
  },
  {
    category: 'Social Channel',
    subcategory: [
      {
        title: "Shopify",
        icon: <FaShopify color="#778491" size={17}/>
      },
      {
        title: "Google",
        icon: <FaGoogle color="#778491" size={15}/>
      },
      {
        title: "Meta",
        icon: <FaMeta color="#778491" size={17}/>
      },
      {
        title: "Email",
        icon: <MdOutlineEmail color="#778491" size={18}/>
      }
    ]
  }
];

const SideMenu = ({onSelect}: any) => {
  const { userData } = useSelector((state: RootState) => state.app);

  const [selected, setSelected] = useState<string>('Profile');
  const onSelectOption = (key: string) => {
    setSelected(key);
    onSelect(key);
  };

  return (
    <div className="h-full min-w-72 sm:border-r border-r-0 border-blue-gray-50">
      <div className="mb-5 flex items-center gap-3">
        <div className="cursor-pointer w-12 h-12">
          {userData?.profilePicture != "" ? <Avatar placeholder src={userData?.profilePicture} alt="avatar" /> :
          <Avatar placeholder src={require("@/assets/images/png/user.png")} alt="avatar" />}
        </div>
        <div>
          <Typography placeholder variant="paragraph" className="font-semibold">{userData?.firstName + " " + userData?.lastName}</Typography>
          <Typography placeholder variant="small" className="font-medium opacity-50">{userData?.customerRole}</Typography>
        </div>
      </div>
      {settings.map((setting, index) => (
        <div key={index}>
          {index != 0 && <hr className="mb-2 border-blue-gray-50" />}
          <p className="text-xs font-semibold text-blue-gray-500">
            {setting.category}
          </p>
          <List placeholder>
            {setting.subcategory.map((subcategory, subIndex) => (
              <ListItemComponent
                key={`${index}${subIndex}`}
                text={subcategory.title}
                icon={subcategory.icon}
                selected={selected === subcategory.title}
                onSelect={() => onSelectOption(subcategory.title)}
              />
            ))}
          </List>
        </div>
      ))}
    </div>
  );
}

export default SideMenu;