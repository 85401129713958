import CustomInput from '@/components/UI/CustomInput';
import { Button,  Popover,
  PopoverHandler,
  PopoverContent, } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { MdOutlineEdit } from "react-icons/md";
import { showToast } from "@/components/UI/PrimaryToast";
import { RootState } from "@/redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import appAction from "@/redux/app/action";
import { apiUploadImage, apiPostUserProfile } from '@/shared/api/user';
import { Spinner } from "@material-tailwind/react";

export const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.app);
  const [firstName, setFirstName] = useState(userData?.firstName || '');
  const [lastName, setLastName] = useState(userData?.lastName || '');
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0)
    {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  };

  const onFileUpload = async () => {
    if (!file) {
      showToast("error", "Please select an image to upload");
      return;
    }

    const res = await apiUploadImage({
      key: 'profileImage',
      imageFile: file
    });

    if(res?.success) {
      dispatch(appAction.loadUserInfoSuccess(res.data));
      showToast("success", "Image uploaded successfully");
    }
    else {
      console.log('Image upload failed!');
    }
  };

  const updateProfile = async () => {
    if (firstName.length == 0 || lastName.length == 0) {
      showToast("error", "Please enter your first name and last name");
      return;
    }

    const res = await apiPostUserProfile({
      firstName,
      lastName
    });

    if(res?.success) {
      console.log(res.data);
      dispatch(appAction.loadUserInfoSuccess(res.data));
      showToast("success", "Profile updated successfully");
    }
    else {
      console.log('Profile update failed!');
    }
  }

  useEffect(() => {
    if(userData) {
      setFirstName(userData.firstName!);
      setLastName(userData.lastName!);
    }
  }, [userData])

  return (
    <>
      <h1 className='text-xl font-semibold border-b border-blue-gray-100 pb-3 mb-6'>Public profile</h1>
      <div className='flex lg:flex-row flex-col gap-12'>
        <div>
          <p className='font-semibold text-sm mb-2'>Profile picture</p>
          <div className='relative'>
            {
              userData?.profilePicture == '' ?
              <img src={require("@/assets/images/png/user.png")} alt="avatar" className='max-w-48 max-h-48 rounded-full' style={{ objectFit: 'cover' }}/> :
              <img src={userData?.profilePicture} alt="avatar" className='w-48 h-48 rounded-full' style={{ objectFit: 'cover' }}/>
            }
            <div className='absolute bottom-2 left-2 z-30'>
              <Popover placement="bottom-start">
                <PopoverHandler>
                  <Button placeholder className="flex items-center gap-1 p-2 capitalize bg-blue-gray-800 border-2 border-gray-400">
                    <MdOutlineEdit size={14}/>Edit
                  </Button>
                </PopoverHandler>
                <PopoverContent placeholder className='p-1 flex flex-col justify-center items-center gap-1 w-[119px] z-40'>
                  {/* <input type="file" onChange={onFileChange} accept="image/*" /> */}
                  <div className="flex items-center">
                    {/* <button
                      onClick={() => document.getElementById('hidden-file-input')?.click()}
                      className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
                    >
                      Choose File
                    </button> */}
                    <Button placeholder variant="text" className='capitalize p-2 w-[110px] truncate' onClick={() => document.getElementById('hidden-file-input')?.click()}>
                      {fileName ? `${fileName}` : "Choose a photo"}
                    </Button>
                    <input
                      type="file"
                      id="hidden-file-input"
                      className="hidden"
                      onChange={onFileChange}
                      accept="image/*"
                    />
                    {/* <span className="ml-3 text-gray-600 text-sm">
                      {fileName ? fileName : 'No file chosen'}
                    </span> */}
                  </div>
                  {fileName && <Button placeholder className='capitalize p-2 w-full' onClick={onFileUpload}>Upload</Button>}
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </div>
        <div className=''>
        <div className='flex xl:flex-row flex-col gap-3'>
          <CustomInput
            label={t("first_name")}
            type="text"
            value={firstName}
            color="black"
            onChangeHandler={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <CustomInput
            label={t("last_name")}
            type="text"
            value={lastName}
            color="black"
            onChangeHandler={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>
        <div className='mt-4'>
          <Button placeholder color="green" className='capitalize' onClick={updateProfile}>Update profile</Button>
        </div>
        </div>
      </div>
    </>
  )
}
