import { Button } from '@material-tailwind/react'
import { RootState } from "@/redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useEffect, useState } from 'react';
import { showToast } from '@/components/UI/PrimaryToast';
interface props {
  klaviyoId?: string;
}
function Email({klaviyoId}: props) {
  const { userId } = useSelector((state: RootState) => state.app);
  const [klaviyo, setKlaviyo] = useState(klaviyoId || "");
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if(klaviyo != '') {
      Cookies.set("klaviyo", "success");
      showToast("success", "Successfully connected to Klaviyo Account");
      setConnected(true);
    }
  }, [klaviyoId])

  useEffect(() => {
    let klaviyoCookie = Cookies.get("klaviyo");
    console.log('cookies.klaviyo :', klaviyoCookie);
    setConnected(klaviyoCookie == "success" ? true : false);
  }, [])


  const onConnectKlaviyo = () => {
    window.location.href =
      process.env.REACT_APP_SERVER_URL + `/user/klaviyo/oauth_url?userId=${userId}`;
  };
  return (
    <>
      <h1 className='text-xl font-semibold border-b border-blue-gray-100 pb-3 mb-6'>Connet to Klaviyo</h1>
      <Button
        placeholder
        className='capitalize'
        color="green"
        variant={connected ? "outlined" : "filled"}
        onClick={() => {
          !connected && onConnectKlaviyo();
        }}
      >
        {connected ? "connected" : "connect"}
      </Button>
    </>
  )
}

export default Email