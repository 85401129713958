import CustomInput from '@/components/UI/CustomInput';
import { showToast } from '@/components/UI/PrimaryToast';
import { apiUpdateShopifyAccInfo } from '@/shared/api/user';
import { Button } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import appAction from "@/redux/app/action";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface props {
  storeName?: string;
}

function Shopify({storeName=""}: props) {
  // const query = useQuery();
  // const shopifyShopName = query.get("shopify");
  const [cookies, setCookie] = useCookies(["shopify"]);
  const dispatch = useDispatch();
  const [shopName, setShopName] = useState(storeName ? storeName.split('.')[0] : "");

  const updateStore = () => {
    if(shopName) {
      onConnect();
    }
    else {
      showToast("error", "Please enter your store name");
    }
  };

  const onConnect = async () => {
    window.location.href = process.env.REACT_APP_SERVER_URL + `/user/shopify/update/oauth_url/?shop=${shopName}.myshopify.com`;
  };

  useEffect(() => {
    if (storeName && storeName!== "") {
      shopifyConnect(storeName);
    }
  }, [storeName]);

  const shopifyConnect = async (shopifyShopName: string) => {
    const result = await apiUpdateShopifyAccInfo({
      shopName: shopifyShopName,
    });

    if (result?.success) {
      setCookie("shopify", "success");
      showToast(
        "success",
        "Successfully connected to Shopify Account"
      );
      dispatch(appAction.loadUserShoipfyInfo({
        shopName: shopName,
      }));
    } else {
      console.log(result?.error ?? result);
    }
  }

  return (
    <>
      <h1 className='text-xl font-semibold border-b border-blue-gray-100 pb-3 mb-6'>Shopify</h1>
      <div className='sm:w-72'>
        <CustomInput
          label={"Store name"}
          type="text"
          value={shopName}
          color="black"
          onChangeHandler={(e) => {
            setShopName(e.target.value);
          }}
        />
      </div>

      <div className='mt-4'>
        <Button placeholder color="green" className='capitalize' onClick={updateStore}>
          {cookies.shopify === 'success' ? "Update" : "Connect"}
        </Button>
      </div>
    </>
  )
}

export default Shopify