import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { styled } from "styled-components";

interface Props {
  children: React.ReactNode;
}

function CarouselItem({children}: Props) {
  const isDesktop = useMediaQuery({ minWidth: 767 });

  return (
    <div>
      <StyledCarouselItem isDesktop={isDesktop}>{children}</StyledCarouselItem>
    </div>
  )
}

export default CarouselItem

const StyledCarouselItem = styled.div<{ isDesktop: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: ${({ isDesktop }) => (isDesktop ? 'calc(100vh - 280px)' : 'calc(100vh - 380px)')};
`;
