import { categorizeDates, hexToRgba } from "@/shared/utils/utils";
import { IconButton } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, MenuItemStyles, menuClasses } from "react-pro-sidebar";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoHome } from "react-icons/io5";
import { FaMeta } from "react-icons/fa6";
import { FaGoogle } from "react-icons/fa";
import { FaShopify } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import PromptRecent from "@/components/UI/PromptRecent";
import { apiGetRecentPrompts } from "@/shared/api/common";
import { RootState } from "@/redux/interfaces";
import { useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";

type Theme = "light" | "dark";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#f0f4f9",
      color: "#000"
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#000",
      hover: {
        backgroundColor: "#fff",
        color: "#000"
      },
      disabled: {
        color: "#999999"
      }
    }
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "#8ba1b7"
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9"
      },
      disabled: {
        color: "#3e5e7e"
      }
    }
  }
};

interface Props {
  onSetCollapsed: (collapsed: boolean) => void;
  onSetToggled: (toggled: boolean) => void;
  onToggled: boolean;
}

const SideProMenu = ({ onSetCollapsed, onToggled, onSetToggled }: Props) => {
  const navigate = useNavigate();
  const { isLoggedIn, isDeletePrompt } = useSelector((state: RootState) => state.app);
  const [theme, setTheme] = React.useState<Theme>("light");
  const [collapsed, setCollapsed] = useState(false);
  const [hasImage, setHasImage] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const [recents, setRecents] = useState<any>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      isLoggedIn && getRecents();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    isLoggedIn && getRecents();
  }, [isDeletePrompt]);

  const getRecents = async () => {
    const result = await apiGetRecentPrompts({});
    if (result?.success) {
      setRecents(categorizeDates(result.data));
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        // setCollapsed(true);
        onSetCollapsed(true);
      }
      else {
        // setCollapsed(false);
        onSetCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set the initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [collapsed]);

  useEffect(() => {
    if(onToggled) {
      setToggled(onToggled);
    }
    if(!toggled) {
      onSetToggled(false);
    }
  }, [onToggled, toggled]);

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
      height: "40px"
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color
      },
      "&:hover": {
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
      }
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9"
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0 ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1) : "transparent"
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color
      },
      "&:hover": {
        backgroundColor: "white",
        boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
      },
      height: "40px",
      margin: "0 60px 0 20px",
      borderRadius: "8px",
      paddingLeft: "0",
      paddingRight: "0"
    },
    label: ({ open }) => ({
      fontWeight: 600
      // fontSize: "14px",
    })
  };
  return (
    <div className="h-screen top-0 left-0">
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
        breakPoint="md"
        backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
        rootStyles={{
          color: themes[theme].sidebar.color,
          height: "100%"
        }}
      >
        <div className="flex flex-col h-full pt-5">
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              icon={<IoHome size={16} />}
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </MenuItem>
            <MenuItem
              icon={<FaMeta size={16} />}
              onClick={() => {
                navigate("/ads/meta");
              }}
            >
              Meta Ads
            </MenuItem>
            <MenuItem
              icon={<FaGoogle size={16} />}
              onClick={() => {
                navigate("/ads/google");
              }}
            >
              Google Ads
            </MenuItem>
            <MenuItem
              icon={<FaShopify size={17} />}
              onClick={() => {
                navigate("/ads/shopify");
              }}
            >
              Shopify
            </MenuItem>
            <MenuItem
              icon={<MdOutlineEmail size={18} />}
              onClick={() => {
                navigate("/ads/email");
              }}
            >
              Email
            </MenuItem>
          </Menu>
          {/* Recent Chats */}
          <Scrollbars
            style={{
              flex: 1,
              marginTop: "24px",
              opacity: collapsed ? 0 : 1
            }}
          >
            <div style={{ padding: "0 24px" }}>
              {recents &&
                recents.map((recent: any, index: number) => (
                  <div key={index}>
                    {recent.data.length > 0 && (
                      <>
                        <p className="font-semibold text-sm">{recent.category}</p>
                        <div className="flex flex-col mt-3 mb-3">
                          {Array.isArray(recent.data) &&
                            recent.data.map((item: any, ind: number) => <PromptRecent key={ind} data={item} />)}
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </div>
          </Scrollbars>
        </div>
      </Sidebar>
    </div>
  );
};

export default SideProMenu;
