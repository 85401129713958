import React, { useState } from 'react'
import CustomInput from '@/components/UI/CustomInput';
import { Button } from '@material-tailwind/react';
import PasswordInput from '@/components/UI/PasswordInput';
import { useTranslation } from 'react-i18next';
import { apiChangePassword } from '@/shared/api/user';
import { showToast } from '@/components/UI/PrimaryToast';

export const ChangePassword = () => {
  const { t } = useTranslation();
  const [oldPwd, setOldPwd] = useState("");
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState<boolean>(true);
  const [matched, setMatched] = useState<boolean>(true);
  const [isCurrent, setIsCurrent] = useState<boolean>(true);
  const [confirmPassword, setConfirmPassword] = useState("");

  const validatePassword = (password: string): boolean => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return regex.test(password);
  };

  const handleNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setMatched(true);
    setIsValid(validatePassword(newPassword));
  }

  const onChangePassword = async () => {
    if (!isValid) {
      return;
    }
    if (password !== confirmPassword) {
      setMatched(false);
      return;
    }

    const res = await apiChangePassword({
      currentPassword : oldPwd,
      newPassword: password
    });

    if(res?.success) {
      showToast("success", "Password changed successfully");
      setIsCurrent(true);
      setMatched(true);
    }
    else {
      console.log(res.message);
      setIsCurrent(false);
    }
  }

  return (
    <>
      <h1 className='text-xl font-semibold border-b border-blue-gray-100 pb-3 mb-6'>Password</h1>
      <div className='flex flex-col gap-3 sm:w-2/5'>
        <PasswordInput
          label={"Current password"}
          name="current password"
          color="black"
          onChangeHandler={(e) => {
            setOldPwd(e.target.value);
            setIsCurrent(true);
          }}
        />
        {!isCurrent && <p className="mb-3 -mt-1 text-sm" style={{ color: 'red' }}>Current password is incorrect</p>}
        <PasswordInput
          label={"New password"}
          name="new password"
          color="black"
          onChangeHandler={handleNewPassword}
        />
        <p className="mb-3 -mt-1 text-sm" style={{ color: !isValid ? 'red' : 'gray' }}>{t("msg_valid_password")}</p>
        <PasswordInput
          label={"Confirm password"}
          name="new password"
          color="black"
          onChangeHandler={(e) => {
            setConfirmPassword(e.target.value);
            setMatched(true);
          }}
        />
        {!matched && <p className="mb-3 -mt-1 text-sm" style={{ color: 'red' }}>Password and confirm password do not match</p>}
        <div className='mt-0'>
          <Button placeholder color="green" className='capitalize' onClick={onChangePassword}>Update password</Button>
        </div>
      </div>

    </>
  )
}