import React, { useCallback, useEffect, useState } from "react";
import { RootState } from "@/redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import appAction from "@/redux/app/action";
import PromptCard from "@/components/UI/PromptCard";
import { CustomSender } from "@/components/UI/CustomSender";
import { Channel } from "@/types/enum";
import { useInfinitePrompts } from "@/hooks/useInfiniteQueries";

interface Props {
  channelType: Channel;
  onViewMore?: () => void;
  onSend?: () => void;
  isLibraryPage?: boolean;
}

function PromptChannel({ channelType: _channelType, onViewMore, onSend, isLibraryPage = false }: Props) {
  const dispatch = useDispatch();
  const { promptLibraryData } = useSelector((state: RootState) => state.app);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(0);
  const [channelType, setChannelType] = useState<Channel>(_channelType);
  const [promptData, setPromptData] = useState<string[]>([]);
  const [promptQuestion, setPromptQuestion] = useState<any>(null);

  const { data, error, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } = useInfinitePrompts(
    channelType, '', 3
  );

  useEffect(() => {
    if(!isLibraryPage && promptData.length > 0) {
      onSelectPromptCard(selectedCardIndex);
    }
  }, [isLibraryPage])


  useEffect(() => {
    if (data) {
      const arrData = data ? data.pages.flatMap((page: any) => page?.data ?? []) : [];
      setPromptData(arrData);
    }
  }, [data]);

  useEffect(() => {
    if(promptLibraryData) {
      setPromptQuestion(promptLibraryData);
    }
  }, [promptLibraryData])

  const onSelectPromptCard = (index: number) => {
    setSelectedCardIndex(index);
    setPromptQuestion(promptData[index]);
    dispatch(appAction.onUpdatePromptLibraryData(promptData[index]));
  };

  return (
    <div className="w-full my-5 text-left">
      {!isLibraryPage && <div className="grid sm:grid-cols-4 grid-cols-2 gap-3 my-4">
        {promptData.length > 0 &&
          promptData.map((item: any, index: number) => (
            <PromptCard
              key={index}
              data={item}
              selected={selectedCardIndex == index}
              onChangeHandler={() => {
                onSelectPromptCard(index);
              }}
            />
          ))}
        <PromptCard data={null} onChangeHandler={onViewMore} />
      </div>}
      <div className="mt-4">
        {promptData.length > 0 && (
          <CustomSender
            data={promptQuestion}
            onChangeHandler={(promptRequestData) => {
              // console.log(promptRequestData)
              dispatch(appAction.onUpdatePromptRequestData(promptRequestData));
              onSend && onSend();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default PromptChannel;
