import { RootState, UserInfo, Shopify } from "@/redux/interfaces";
import actions from "./action";
import { IKPI, DateRange } from "@/types/basic";

export type RootAppState = RootState["app"];

interface State extends RootAppState {
  isLoggedIn: boolean;
  userData?: UserInfo;
  userId?: number;
  shopify?: Shopify;
  dateRange?: DateRange;
  promptLibraryData: any;
  promptRequestData: any;
  promptResponseData: any;
  isResponsePage: boolean;
  isDeletePrompt: boolean;
  shopifyKpiData?: IKPI[];
  googleKpiData?: IKPI[];
  metaKpiData?: IKPI[];
}

interface Action extends RootAppState {
  type: string;
  payload: any;
}

const initalState: State = {
  isLoggedIn: false,
  userId: undefined,
  userData: undefined,
  shopify: undefined,
  dateRange: undefined,
  promptLibraryData: null,
  promptRequestData: null,
  promptResponseData: null,
  isResponsePage: false,
  isDeletePrompt: false,
  shopifyKpiData: undefined,
  googleKpiData: undefined,
  metaKpiData: undefined,
};

export default function appReducer(
  state: RootState["app"] = initalState,
  action: Action
) {
  const { type, payload } = action;

  switch (type) {
    case actions.SUCCESS_LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        userId: payload.id,
        userData: payload,
      };
    case actions.SUCCESS_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        userId: undefined,
        userData: undefined,
        // shopify: undefined,
        dateRange: undefined,
        isResponsePage: false,
        isDeletePrompt: false,
      };
    case actions.LOAD_USER_INFO:
      return {
        ...state,
      };
    case actions.LOAD_USER_INFO_SUCCESS:
      return {
        ...state,
        userData: payload,
      };
    case actions.LOAD_USER_SHOPIFY_INFO:
      return {
        ...state,
        shopify: payload,
      };
    case actions.UPDATE_DATE_RANGE:
      return {
        ...state,
        dateRange: payload,
      };
    case actions.UPDATE_PROMPT_LIBRARY_DATA:
      return {
        ...state,
        promptLibraryData: payload,
      };
    case actions.UPDATE_PROMPT_REQUEST_DATA:
      return {
        ...state,
        promptRequestData: payload,
      };
    case actions.UPDATE_PROMPT_RESPONSE_DATA:
      return {
        ...state,
        promptResponseData: payload,
      };
    case actions.UPDATE_IS_RESPONSE_PAGE:
      return {
        ...state,
        isResponsePage: payload,
      };
    case actions.DELETE_PROMPT:
      return {
        ...state,
        isDeletePrompt: payload,
      };
    case actions.UPDATE_SHOPIFY_DATA:
      return {
        ...state,
        shopifyKpiData: payload,
      };
    case actions.UPDATE_GOOGLE_DATA:
      return {
        ...state,
        googleKpiData: payload,
      };
    case actions.UPDATE_META_DATA:
      return {
        ...state,
        metaKpiData: payload,
      };
    default:
      return state;
  }
}
