import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import "./index.styles.scss";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  data?: any;
}

function HelpCard({data}: Props) {
  const navigate = useNavigate();
  const onClickHandler = useCallback(() => {
    if(data?.id) {
      navigate("/help/" + data?.id);
    }
  }, [data]);

  return (
    <Card placeholder className="w-full flex flex-col justify-between" style={{ height: '253px' }}>
      <CardBody placeholder>
        <Typography placeholder variant="h5" color="blue-gray" className="mb-2 ellipsis-multiline-2">
          {data?.title || "title"}
        </Typography>
        <Typography placeholder className="ellipsis-multiline-3">
          {data?.description || "description"}
        </Typography>
      </CardBody>
      <CardFooter placeholder className="pt-0">
        <Button placeholder onClick={onClickHandler}>Read More</Button>
      </CardFooter>
    </Card>
  )
}

export default HelpCard