import React, { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import {
  addMonths,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  endOfWeek,
  subWeeks,
  subMonths
} from "date-fns";
import { normalizeDate } from "@/shared/utils/shopify";
import { DateRange } from "@/types/basic";
import { getCompareDateRange, toISODateString } from "@/shared/utils/utils";

const customStaticRanges_three_month = [
  {
    label: "Last Three Months",
    range: () => ({
      startDate: addMonths(startOfMonth(new Date()), -3),
      endDate: endOfMonth(addMonths(new Date(), -1))
    }),
    isSelected(range: any) {
      const {startDate, endDate} = range;
      const definedRange = this.range();
      return (
        startDate === toISODateString(definedRange.startDate) &&
        endDate === toISODateString(definedRange.endDate)
      );
    }
  }
];

const predefinedRanges = [
  {
    label: "Today",
    isSelected: (range: DateRange) => {
      const {startDate, endDate} = range;
      const _startOfWeek = toISODateString(startOfToday());
      const _endOfWeek = toISODateString(endOfToday());
      return (
        startDate === _startOfWeek &&
        endDate === _endOfWeek
      );
    }
  },
  {
    label: "Yesterday",
    isSelected: (range: DateRange) => {
      const {startDate, endDate} = range;
      const _startOfWeek = toISODateString(startOfYesterday());
      const _endOfWeek = toISODateString(endOfYesterday());
      return (
        startDate === _startOfWeek &&
        endDate === _endOfWeek
      );
    }
  },
  {
    label: "This Week",
    isSelected: (range: DateRange) => {
      const {startDate, endDate} = range;
      const now = new Date();
      const _startOfWeek = toISODateString(startOfWeek(now));
      const _endOfWeek = toISODateString(endOfWeek(now));
      return (
        startDate === _startOfWeek &&
        endDate === _endOfWeek
      );
    }
  },
  {
    label: "This Month",
    isSelected: (range: DateRange) => {
      const {startDate, endDate} = range;
      const now = new Date();
      const _startOfMonth = toISODateString(startOfMonth(now));
      const _endOfMonth = toISODateString(endOfMonth(now));
      return (
        startDate === _startOfMonth &&
        endDate === _endOfMonth
      );
    }
  },
  {
    label: "Last Week",
    isSelected: (range: DateRange) => {
      const {startDate, endDate} = range;
      const now = new Date();

      const lastWeekStart = toISODateString(startOfWeek(subWeeks(now, 1)));
      const lastWeekEnd = toISODateString(endOfWeek(subWeeks(now, 1)));
      return (
        startDate === lastWeekStart &&
        endDate === lastWeekEnd
      );
    }
  },
  {
    label: "Last Month",
    isSelected: (range: DateRange) => {
      const {startDate, endDate} = range;
      const now = new Date();
      const lastMonthStart = toISODateString(startOfMonth(subMonths(now, 1)));
      const lastMonthEnd = toISODateString(endOfMonth(subMonths(now, 1)));
      return (
        startDate === lastMonthStart &&
        endDate === lastMonthEnd
      );
    }
  },
  customStaticRanges_three_month[0]
];

interface Props {
  dateRange: DateRange;
}

const CompareDateRange = ({ dateRange }: Props) => {
  const [date, setDate] = useState<string>("");

  const checkSelectedRange = (range: DateRange) => {
    for (const predefinedRange of predefinedRanges) {
      if (predefinedRange.isSelected(range)) {
        return predefinedRange.label;
      }
    }
    const {startDate, endDate} = range;
    if (startDate == endDate) {
      return startDate;
    }
    return `${startDate + " ~ " + endDate}`;
  };

  useEffect(() => {
    if(dateRange)
    {
      // console.log('in: ', dateRange);
      // console.log('before: ', getCompareDateRange(dateRange));
      const compareRange = getCompareDateRange(dateRange);
      setDate(checkSelectedRange(compareRange));
    }
  }, [dateRange]);

  return (
    <Button placeholder className="flex items-center gap-2 rounded-md" size="sm" color="white">
      <p className="text-xs font-normal capitalize">
        {"Compare to: "}
        {date}
      </p>
    </Button>
  );
};

export default CompareDateRange;
