import React, { useEffect, useState } from 'react'
import "./index.styles.scss";
import { Avatar } from "@material-tailwind/react";
import { PiStarFourThin } from "react-icons/pi";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import { GiSettingsKnobs } from "react-icons/gi";
import { IoShareSocialOutline } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { Spinner } from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "@/redux/interfaces";
import appAction from "@/redux/app/action";
import Scrollbars from 'react-custom-scrollbars-2';
import { apiSendPrompt } from '@/shared/api/common';


function PromptResponsePage() {
  const dispatch = useDispatch();
  const { userData, promptRequestData, promptResponseData } = useSelector((state: RootState) => state.app);
  const [response, setResponse] = useState<any>(null);

  useEffect(() => {
    dispatch(appAction.onUpdatePromptRequestData(null));
  }, [])

  useEffect(() => {
    if (promptRequestData) {
      setResponse(null);
      onSendPrompt();
    }
  }, [promptRequestData])

  const onSendPrompt = async() => {
    // console.log('>> Prompt Request Data \n', promptRequestData);

    const res = await apiSendPrompt(promptRequestData);
    if(res?.success) {
      setResponse(res.data);
    }
  }

  // useEffect(() => {
  //   if (promptResponseData) {
  //     setResponse(promptResponseData);
  //   }
  // }, [promptResponseData])

  const formatResponse = (text: any) => {
    if (!text) return;
    return text.split('\n').map((item: any, index: number) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <Scrollbars className="text-xs text-left">
      <div className='flex gap-3 mb-5 pt-3 items-center'>
        <div className='w-6'>
          {
            userData?.profilePicture == '' ?
            <Avatar placeholder src={require("@/assets/images/png/user.png")} alt="avatar" size='xs' /> :
            <Avatar placeholder src={userData?.profilePicture} alt="avatar" size='xs' />
          }
        </div>
        <p className='flex-1 underline decoration-blue-500 decoration-2 underline-offset-4'>{response?.prompt}</p>
      </div>
      <div className='flex gap-3'>
        <div className='w-6 justify-center'>
          <PiStarFourThin size={20} />
        </div>
        <div className='flex-1 flex flex-col gap-4'>
          {response ? formatResponse(response?.response) : <Spinner className='mx-auto' />}
          {/* <div className='flex gap-3 my-5'>
            <AiOutlineLike size={17} />
            <AiOutlineDislike size={17} />
            <GiSettingsKnobs size={17} />
            <IoShareSocialOutline size={17} />
            <FcGoogle size={17} />
            <PiDotsThreeVerticalBold size={17} />
          </div>
          <img src={require("@/assets/images/png/charts.png")} alt="charts" /> */}
        </div>
      </div>
    </Scrollbars>
  )
}

export default PromptResponsePage