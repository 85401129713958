import { styled } from "styled-components";
import "./index.styles.scss";
import { BiSend } from "react-icons/bi";
import { IconButton } from "@material-tailwind/react";
import TextSelect from "@/components/UI/TextSelect";
import { useState } from "react";
import { apiSendPrompt } from "@/shared/api/common";
import { getDateRange } from "@/shared/utils/utils";
import { DateRange } from "@/types/basic";

interface Props {
  data: any;
  bgColor?: string;
  selected?: boolean;
  onChangeHandler?: (prompt: any) => void;
}

export const CustomSender = ({ data, bgColor = "#f0f4f9", selected = false, onChangeHandler }: Props) => {
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});

  const handleSelectChange = (placeholder: string, value: string) => {
    setSelectedValues((prev) => ({
      ...prev,
      [placeholder]: value,
    }));
  };

  const parts = data && data.prompt.split(/(<[^>]+>)/g);

  const getText = () => {
    return parts.map((part: any) => {
      const placeholderMatch = part.match(/<([^>]+)>/);
      if (placeholderMatch) {
        const placeholder = placeholderMatch[1];
        return selectedValues[placeholder] || placeholder;
      }
      return part;
    }).join('');
  };

  const sendPrompt = async() => {
    console.log('selectedValues', selectedValues);
    const { _id, __v, ...rest } = data;
    const { DATE_RANGE, ...values } = selectedValues;
    const dateRange: DateRange = getDateRange(DATE_RANGE);
    const req = { ...rest, promptId: _id, prompt: {...values, dateRange, prompt: getText()} };
    if(onChangeHandler) {
      onChangeHandler(req);
    }
  }

  return (
    <Container selected={selected} bgColor={bgColor} className="w-full pt-3.5 pb-1 px-3.5 rounded-lg cursor-pointer">
      {!!data && <div className="flex flex-wrap items-center font-normal text-[13px]">
        {/* <span>{data._id}</span> */}
        {parts.map((part: string, index: number) => {
          const placeholderMatch = part.match(/<([^>]+)>/);
          if (placeholderMatch) {
            const placeholder = placeholderMatch[1];
            if (data[placeholder]) {
              const options = data[placeholder];
              return <TextSelect key={`text_select_${index}`} options={options} onChange={(value: string) => handleSelectChange(placeholder, value)}/>;
            }
          } else {
            return <span key={index}>{part}</span>;
          }
        })}
      </div>}
      <div className="flex justify-end">
        <IconButton placeholder variant="text" onClick={sendPrompt}>
          <BiSend size={18} />
        </IconButton>
      </div>
    </Container>
  );
};

const Container = styled.div<{ selected?: boolean; bgColor?: string }>`
  box-shadow: ${({ selected }) =>
    selected
      ? "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
      : "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#f0f4f9")};
`;
