import { ISalesData } from "@/types/props";

export const normalizeDate = (date: Date) => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

export const getTotalSales = (salesData: any) => {
  const totalSalesSum = salesData.reduce(
    (sum: any, record: any) => sum + record.totalSales,
    0
  );
  return totalSalesSum;
};

export const sortDataByDate = (data: any) => {
  return data.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
};

export const getTotalValue = (salesData: any, key: string) => {
  const totalSalesSum = salesData.reduce(
    (sum: any, record: any) => sum + record[key],
    0
  );
  return parseFloat(totalSalesSum.toFixed(2));
};

export const calculatePercentageChange = (
  sales: number,
  beforeSales: number
): number => {
  if (beforeSales === 0) {
    if (sales > 0) {
      return 100;
    } else {
      return 0;
    }
  }

  if (sales === 0) {
    return -100;
  }

  if (sales === beforeSales) {
    console.log('No sales change');
    return 0;
  }

  const change = ((sales - beforeSales) / beforeSales) * 100;

  if (change > 0) {
    // console.log(`Increased by ${change.toFixed(2)}%`);
    return parseFloat(change.toFixed(2));
  } else {
    // console.log(`Decreased by ${Math.abs(change).toFixed(2)}%`);
    return parseFloat(change.toFixed(2));
  }
};

export const calculateDivide = (totalSales: number, orderCount: number) => {
  if(totalSales == 0 || orderCount == 0) return 0;
  return parseFloat((totalSales / orderCount).toFixed(2));
}

export const calculatePercentage = (a: number, b: number) => {
  if(a == 0 || b == 0) return 0;
  return parseFloat(((a / b) * 100).toFixed(2));
}

export const formatLargeNumber = (num: number) => {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(1) + "B";
  } else if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + "M";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + "K";
  }
  return parseFloat(num.toFixed(2)).toString();
};

export const getTotalVisitors = (data: any) => {
  const totalVisitors = data.reduce(
    (sum: any, record: any) => sum + record.visitors,
    0
  );
  return totalVisitors;
};