import CustomInput from "@/components/UI/CustomInput";
import { apiForgetPassword, apiResetPassword } from "@/shared/api/user";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showToast } from "@/components/UI/PrimaryToast";
import { Button } from "@material-tailwind/react";
import PasswordInput from "@/components/UI/PasswordInput";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const query = useQuery();
  const token = query.get("token");

  const validatePassword = (password: string): boolean => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if(!password || !confirmPassword) {
      setErrorMessage('Please enter password and confirm password.');
      return;
    }
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    if (!isValid) {
      setErrorMessage(t("msg_too_easy_to_guess"));
      return;
    }

    try {
      const res = await apiResetPassword({
        token: token!,
        password
      });
      if(res?.success) {
        showToast('success','Password reset successful. Redirecting to login...');
        setErrorMessage('');

        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
      else {
        setErrorMessage(res?.error ?? res);
      }
    } catch (error) {
      setErrorMessage('Something went wrong, please try again.');
    }
  };

  const renderError = () => {
    if (errorMessage != "") {
      return <p className="w-full text-red-500 mt-2 -mb-5 font-regular font14">{errorMessage}</p>;
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-200 px-3">
      <div className=" flex flex-col text-black w-full max-w-lg px-3">
        <p className="text-2xl text-center font-bold mb-3">{t("reset_password")}</p>
        <p className="text-center opacity-50 mb-6">{"Enter your email and we'll send you a link to reset your password"}</p>
        <PasswordInput
          label={"Enter new password"}
          name="password"
          color="black"
          onChangeHandler={(e) => {
            setPassword(e.target.value);
            const password = e.target.value;
            setPassword(password);
            const valids = validatePassword(password); console.log('valids', valids);
            setIsValid(valids);
          }}
        />
        <p className="mb-3 -mt-1 text-sm" style={{ color: !isValid ? 'red' : 'gray' }}>{t("msg_valid_password")}</p>
        <PasswordInput
          label={"Confirm new password"}
          name="confirm password"
          color="black"
          onChangeHandler={(e) => {
            setConfirmPassword(e.target.value);
          }}
        />
        {renderError()}
        <Button
          className="justify-center capitalize text-base py-2.5 mt-10"
          fullWidth
          placeholder
          size={"sm"}
          color="blue"
          loading={isLoading}
          onClick={handleSubmit}
        >
          {t("submit")}
        </Button>
        {/* <div className="flex justify-center items-center text-sm mt-4">
          <Link to={`/login`} className="ml-2 text-sm text-blue-500">
            {t("back_to_login")}
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default ResetPasswordPage