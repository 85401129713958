import { DefaultTheme } from "styled-components";

const color = {
  atom: "#fafafa", //"#282c34",
  correct: "#5babab",
  present: "#fdb800",
  absent: "#908790",
};

export const darkTheme: DefaultTheme = {
  backgroundColor: color.atom,
  textColor: 'white',
  borderColor: 'white',
};

export const lightTheme: DefaultTheme = {
  backgroundColor: 'white',
  textColor: 'black',
  borderColor: 'black',
};
