import { FormImage } from "@/types/basic";
import HttpClient from "./apiClient";

export async function apiRegister(params: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyName: string;
  clientType: string;
  customerRole: string;
}) {
  try {
    const response = await HttpClient.post("/user/create", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiLogin(params: {
  email: string;
  password: string;
}) {
  try {
    const response = await HttpClient.post("/user/login", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
    const err = {
      success: false,
      error: (e as any).response.data.message
    }
    return err;
  }
}


export async function apiLogout() {
  try {
    const response = await HttpClient.getWithToken("/user/logout", {});
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiForgetPassword(params: {
  email: string;
}) {
  try {
    const response = await HttpClient.post("/user/forget-password", {...params});
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiChangePassword(params: {
  currentPassword: string;
  newPassword: string;
}) {
  try {
    const response = await HttpClient.postWithToken("/user/changePassword", {
      ...params,
    });
    return response?.data;
  } catch (e) {
    const err = {
      success: false,
      error: (e as any).response.data.message
    }
    return err;
  }
}

export async function apiResetPassword(params: {
  token: string;
  password: string;
}) {
  try {
    const response = await HttpClient.post("/user/reset-password", {
      ...params,
    });
    return response?.data;
  } catch (e) {
    const err = {
      success: false,
      error: (e as any).response.data.message
    }
    return err;
  }
}

export async function apiRequestRecoverPassword(params: { email: string }) {
  try {
    const response = await HttpClient.post("/user/requestRecoverPassword", {
      ...params,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserInfo(params: { userId: string }) {
  try {
    const response = await HttpClient.get(`user/info`, {...params});
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiPostUserProfile(params: { firstName: string, lastName: string }) {
  try {
    const response = await HttpClient.postWithToken(`user/info/profile`, {...params});
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiPostUserRoles(params: { clientType: string;  customerRole: string; }) {
  try {
    const response = await HttpClient.postWithToken(`user/info/roles`, {...params});
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetFacebookAdAccount(params: { facebookId: string }) {
  try {
    const response = await HttpClient.getWithToken("/user/facebook/ad/account", {
      ...params,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
    const err = {
      success: false,
      error: (e as any).response.data.message
    }
    return err;
  }
}

export async function apiGetFacebookAdInfo(params: {
  facebookId: string;
  adAccountId: string;
  pixelId: string;
})
{
  try {
    const response = await HttpClient.postWithToken("/user/facebook/ad/info", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetGoogleCustomers(params: { googleId: string }) {
  try {
    const response = await HttpClient.getWithToken("/user/google/customer", {
      ...params,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
    const err = {
      success: false,
      error: (e as any).response.data.message
    }
    return err;
  }
}

export async function apiGetGoogleAdAccount(params: { googleId: string, customerId: string }) {
  try {
    const response = await HttpClient.getWithToken("/user/google/ad/account", {
      ...params,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
    const err = {
      success: false,
      error: (e as any).response.data.message
    }
    return err;
  }
}

export async function apiGetGoogleAdInfo(params: {
  googleId: string;
  adAccountId: string;
  analyticViewerId: string;
})
{
  try {
    const response = await HttpClient.postWithToken("/user/google/ad/info", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyAccInfo(params: {
  shopName: string;
})
{
  try {
    const response = await HttpClient.postWithToken("/user/shopify/ad/info", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiUpdateShopifyAccInfo(params: {
  shopName: string;
})
{
  try {
    const response = await HttpClient.postWithToken("/user/shopify/update/ad/info", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetSocialConnects() {
  try {
    const response = await HttpClient.getWithToken("/user/socials", {});
    return response?.data;
  } catch (e) {
    console.log(e);
    const err = {
      success: false,
      error: (e as any).response.data.message
    }
    return err;
  }
}

export async function apiUploadImage(params: FormImage) {
  try {
    const response = await HttpClient.uploadImageWithToken("/user/upload", params);
    return response?.data;
  } catch (e) {
    const err = {
      success: false,
      error: (e as any).response.data.message
    }
    return err;
  }
}