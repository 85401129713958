import { DateRange, IKPI } from "@/types/basic";
import { Channel } from "@/types/enum";
import { startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, subDays, subWeeks, subMonths, parseISO, isToday, isYesterday, format, getYear } from 'date-fns';
import { fromZonedTime, } from 'date-fns-tz';

export const validateEmail = (email: any) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// hex to rgba converter
export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getDate = (isoDateString: string): string => {
  return isoDateString.split('T')[0];
}

export const toISODateString = (date: Date): string => {
  // return date.toISOString().split('T')[0];
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function mergeAndConsolidateArrays(array1: any, array2: any): any {
  const consolidatedData: any = {};

  const addToConsolidatedData = (array: any): void => {
    array.forEach((item: any) => {
      const { date, spend, conv, roas, cpa } = item;
      if (consolidatedData[date]) {
        consolidatedData[date].spend += spend;
        consolidatedData[date].conv += conv;
        consolidatedData[date].roas += roas;
        consolidatedData[date].cpa += cpa;
      } else {
        consolidatedData[date] = {
          spend,
          conv,
          roas,
          cpa
        };
      }
    });
  };

  addToConsolidatedData(array1);
  addToConsolidatedData(array2);

  const result: any = Object.keys(consolidatedData).map(date => ({
    date,
    spend: parseFloat(consolidatedData[date].spend.toFixed(2)),
    conv: parseFloat(consolidatedData[date].conv.toFixed(2)),
    roas: parseFloat(consolidatedData[date].roas.toFixed(2)),
    cpa: parseFloat(consolidatedData[date].cpa.toFixed(2))
  }));

  result.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return result;
}

interface googleMetaItem {
  date: string;
  spend: number;
  conv: number;
  roas: number;
  cpa: number;
}

interface salesItem {
  date: string;
  totalSales: number;
  orderCount: number;
}

interface CombinedItem extends googleMetaItem, salesItem {}

export const mergeArraysByDate_googleMeta_sales = (a: googleMetaItem[], b: salesItem[]): CombinedItem[] => {
  const merged: CombinedItem[] = [];

  const allDates = new Set([...a.map(item => item.date), ...b.map(item => item.date)]);

  allDates.forEach(date => {
    const aItem = a.find(item => item.date === date);
    const bItem = b.find(item => item.date === date);

    merged.push({
      date: date,
      spend: aItem?.spend ?? 0,
      conv: aItem?.conv ?? 0,
      roas: aItem?.roas ?? 0,
      cpa: aItem?.cpa ?? 0,
      totalSales: bItem?.totalSales ?? 0,
      orderCount: bItem?.orderCount ?? 0,
    });
  });

  merged.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return merged;
};

interface GMItem {
  date: string;
  meta_spend: number;
  meta_conv: number;
  meta_roas: number;
  meta_cpa: number;
  google_spend: number;
  google_conv: number;
  google_roas: number;
  google_cpa: number;
}

export const mergeSameArraysByDate = (meta: googleMetaItem[], google: googleMetaItem[]): GMItem[] => {
  const merged: GMItem[] = [];
  const allDates = new Set([...meta.map(item => item.date), ...google.map(item => item.date)]);
  allDates.forEach(date => {
    const metaItem = meta.find(item => item.date === date);
    const googleItem = google.find(item => item.date === date);

    merged.push({
      date: date,
      meta_spend: metaItem?.spend ?? 0,
      meta_conv: metaItem?.conv ?? 0,
      meta_roas: metaItem?.roas ?? 0,
      meta_cpa: metaItem?.cpa ?? 0,
      google_spend: googleItem?.spend ?? 0,
      google_conv: googleItem?.conv ?? 0,
      google_roas: googleItem?.roas ?? 0,
      google_cpa: googleItem?.cpa ?? 0,
    });
  });

  merged.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return merged;
}

export const channelTypeToIndex: Record<Channel, number> = {
  [Channel.AllChannels]: 0,
  [Channel.MetaAds]: 1,
  [Channel.GoogleAds]: 2,
  [Channel.Shopify]: 3,
  [Channel.Email]: 4,
};

export const indexToChannelType: Record<number, Channel> = {
  0: Channel.AllChannels,
  1: Channel.MetaAds,
  2: Channel.GoogleAds,
  3: Channel.Shopify,
  4: Channel.Email,
};

export const updateKpiData = (kpiData: IKPI[] | undefined, newKpiData: IKPI): IKPI[] => {
  if (!kpiData) {
    return [newKpiData];
  }
  const newRange = newKpiData.range;

  // Check if existing data has the same date-range
  const isDuplicate = kpiData.some(
    (kpi) =>
      kpi.range.startDate === newRange.startDate &&
      kpi.range.endDate === newRange.endDate
  );

  // Add data only if it is not duplicated
  if (!isDuplicate) {
    return [...kpiData, newKpiData];
  }

  // If there is a duplicate, return the existing data as is.
  return kpiData;
}

export const findKpiByRange = (kpiData: IKPI[] | undefined, range: DateRange): IKPI | undefined => {
  if (!kpiData) {
    return undefined;
  }
  if (!Array.isArray(kpiData)) {
    return undefined;
  }
  const EXPIRATION_TIME = 4 * 60 * 60 * 1000; // 4 hours
  const currentTime = new Date().getTime();
  const foundKpiIndex = kpiData.findIndex(
    (kpi) =>
      kpi.range.startDate === range.startDate &&
      kpi.range.endDate === range.endDate
  );
  if (foundKpiIndex === -1) {
    return undefined;
  }
  const foundKpi = kpiData[foundKpiIndex];
  const createdAtTime = new Date(foundKpi.createdAt).getTime();

  // If the createdAt time is more than EXPIRATION_TIME hours old
  if (currentTime - createdAtTime > EXPIRATION_TIME) {
    kpiData.splice(foundKpiIndex, 1);
    return undefined;
  }

  return foundKpi;
}

export const getDateRange = (date_label: string, timeZone: string = 'UTC'): DateRange => {
  const now = new Date();

  let startDate: Date;
  let endDate: Date;

  switch (date_label) {
    case 'Today':
      startDate = fromZonedTime(startOfDay(now), timeZone);
      endDate = fromZonedTime(endOfDay(now), timeZone);
      break;
    case 'Yesterday':
      startDate = fromZonedTime(startOfDay(subDays(now, 1)), timeZone);
      endDate = fromZonedTime(endOfDay(subDays(now, 1)), timeZone);
      break;
    case 'This Week':
      startDate = fromZonedTime(startOfWeek(now), timeZone);
      endDate = fromZonedTime(endOfWeek(now), timeZone);
      break;
    case 'Last Week':
      startDate = fromZonedTime(startOfWeek(subWeeks(now, 1)), timeZone);
      endDate = fromZonedTime(endOfWeek(subWeeks(now, 1)), timeZone);
      break;
    case 'This Month':
      startDate = fromZonedTime(startOfMonth(now), timeZone);
      endDate = fromZonedTime(endOfMonth(now), timeZone);
      break;
    case 'Last Month':
      startDate = fromZonedTime(startOfMonth(subMonths(now, 1)), timeZone);
      endDate = fromZonedTime(endOfMonth(subMonths(now, 1)), timeZone);
      break;
    case 'Last Three Months':
      startDate = fromZonedTime(startOfMonth(subMonths(now, 3)), timeZone);
      endDate = fromZonedTime(endOfMonth(subMonths(now, 1)), timeZone);
      break;
    default:
      throw new Error('Invalid date label');
  }

  return {
    startDate: toISODateString(startDate),
    endDate: toISODateString(endDate),
  };
};


export const categorizeDates = (data: any) => {
  const timeZone = 'UTC'
  const now = new Date();
  const currentYear = getYear(now);
  const last7DaysStart = fromZonedTime(startOfDay(subDays(now, 7)), timeZone);
  const last30DaysStart = fromZonedTime(startOfDay(subDays(now, 30)), timeZone);
  const twoMonthsAgo = fromZonedTime(startOfDay(subDays(now, 60)), timeZone);

  const categorized: any = {
    Today: [],
    Yesterday: [],
    Previous7Days: [],
    Previous30Days: [],
    Months: {},
    Year: []
  };

  data.forEach((item: any) => {
    const date = fromZonedTime(parseISO(item.createdAt), timeZone);
    const itemYear = getYear(date);

    if (isToday(date)) {
      categorized.Today.push(item);
    } else if (isYesterday(date)) {
      categorized.Yesterday.push(item);
    } else if (date >= last7DaysStart) {
      categorized.Previous7Days.push(item);
    } else if (date >= last30DaysStart) {
      categorized.Previous30Days.push(item);
    } else if (date >= twoMonthsAgo) {
      const month = format(date, 'MMMM');
      if (!categorized.Months[month]) {
        categorized.Months[month] = [];
      }
      categorized.Months[month].push(item);
    } else if(itemYear === currentYear){
      categorized.Year.push(item);
    }
  });

  // Convert Months and Year object into arrays for ordered categories
  const orderedMonths = Object.keys(categorized.Months).map(month => ({
    category: month,
    data: categorized.Months[month],
  }));

  const orderedCategorized = [
    { category: 'Today', data: categorized.Today },
    { category: 'Yesterday', data: categorized.Yesterday },
    { category: 'Previous 7 Days', data: categorized.Previous7Days },
    { category: 'Previous 30 Days', data: categorized.Previous30Days },
    ...orderedMonths,
    { category: 'This Year', data: categorized.Year },
  ];

  return orderedCategorized;
};

export const getCompareDateRange = (dateRange: DateRange): DateRange => {
  const { startDate, endDate } = dateRange;

  const start = new Date(startDate);
  const end = new Date(endDate);

  const differenceInDays = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));

  const compareStartDate = new Date(start);
  const compareEndDate = new Date(end);

  compareStartDate.setDate(start.getDate() - (differenceInDays));
  compareEndDate.setDate(end.getDate() - (differenceInDays));

  return {
    startDate: toISODateString(compareStartDate),
    endDate: toISODateString(compareEndDate),
  };
};