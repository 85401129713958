import { styled } from "styled-components";

interface Props {
  data: any;
  bgColor?: string;
  selected?: boolean;
  onChangeHandler?: (e: any) => void;
}

export default function PromptCard({ data, bgColor = "#f0f4f9", selected = false, onChangeHandler }: Props) {
  return (
    <Container
      selected={selected}
      bgColor={bgColor}
      className="w-full p-2 rounded-lg cursor-pointer"
      onClick={onChangeHandler}
    >
      <div className="flex justify-center items-center h-full">
        <p className="font-normal text-[13px] line-clamp-3 mb-1">{data ? data.label : "View More"}</p>
      </div>
    </Container>
  );
}

const Container = styled.div<{ selected?: boolean; bgColor?: string }>`
  box-shadow: ${({ selected }) =>
    selected
      ? "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
      : "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"};
  background-color: ${({ selected, bgColor }) => (selected ? "#d3e3fd" : "#f0f4f9")};
`;
