import React, { useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { RootState } from "@/redux/interfaces";
import { useSelector } from "react-redux";

export function PrivateRoute({
  children
}: React.PropsWithChildren): JSX.Element {
  const { isLoggedIn } = useSelector((state: RootState) => state.app);

  const { pathname } = useLocation();

  // Set the visitedURL cookie only when pathname changes
  useEffect(() => {
    Cookies.set('visitedURL', pathname);
  }, [pathname]);

  if (!isLoggedIn) {
    return <Navigate to={`/login?from=${pathname}`} />;
  }
  else if ((Cookies.get('socialsConnected') != 'success')) {
    return <Navigate to={`/connects`} />;
  }

  return children as JSX.Element;
}