import { ResetPasswordPage, ForgetPasswordPage, LoginPage, SignUpPage, SocialConnects } from '@/pages';
import { AppLayout } from '@/layouts/AppLayout';
import { EmptyLayout } from '@/layouts/EmptyLayout';

export const publicRoutes = [
  {
    path: '/login',
    element: <LoginPage />,
    layout: EmptyLayout
  },
  {
    path: '/signup',
    element: <SignUpPage />,
    layout: EmptyLayout
  },
  {
    path: '/connects',
    element: <SocialConnects />,
    layout: EmptyLayout
  },
  {
    path: '/forget-password',
    element: <ForgetPasswordPage />,
    layout: EmptyLayout
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />,
    layout: EmptyLayout
  },
];
