import React, { useCallback, useEffect, useRef, useState } from "react";
import { Popover, PopoverHandler, PopoverContent, Button } from "@material-tailwind/react";
import { MdOutlineDateRange } from "react-icons/md";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { createStaticRanges, DateRangePicker, RangeKeyDict, defaultStaticRanges } from "react-date-range";
import {
  addMonths,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  endOfWeek,
  subWeeks,
  subMonths
} from "date-fns";
import { normalizeDate } from "@/shared/utils/shopify";
import { DateRange } from "@/types/basic";
import { getDate, toISODateString } from "@/shared/utils/utils";
import { useMediaQuery } from "react-responsive";

const customStaticRanges_three_month = [
  {
    label: "Last Three Months",
    range: () => ({
      startDate: addMonths(startOfMonth(new Date()), -3),
      endDate: endOfMonth(addMonths(new Date(), -1))
    }),
    isSelected(range: any) {
      const {startDate, endDate} = range;
      const definedRange = this.range();
      return (
        startDate === toISODateString(definedRange.startDate) &&
        endDate === toISODateString(definedRange.endDate)
      );
    }
  }
];

const combinedStaticRanges = createStaticRanges([...defaultStaticRanges, ...customStaticRanges_three_month]);

const predefinedRanges = [
  {
    label: "Today",
    isSelected: (range: DateRange) => {
      const {startDate, endDate} = range;
      const _startOfWeek = toISODateString(startOfToday());
      const _endOfWeek = toISODateString(endOfToday());
      return (
        startDate === _startOfWeek &&
        endDate === _endOfWeek
      );
    }
  },
  {
    label: "Yesterday",
    isSelected: (range: DateRange) => {
      const {startDate, endDate} = range;
      const _startOfWeek = toISODateString(startOfYesterday());
      const _endOfWeek = toISODateString(endOfYesterday());
      return (
        startDate === _startOfWeek &&
        endDate === _endOfWeek
      );
    }
  },
  {
    label: "This Week",
    isSelected: (range: DateRange) => {
      const {startDate, endDate} = range;
      const now = new Date();
      const _startOfWeek = toISODateString(startOfWeek(now));
      const _endOfWeek = toISODateString(endOfWeek(now));
      return (
        startDate === _startOfWeek &&
        endDate === _endOfWeek
      );
    }
  },
  {
    label: "This Month",
    isSelected: (range: DateRange) => {
      const {startDate, endDate} = range;
      const now = new Date();
      const _startOfMonth = toISODateString(startOfMonth(now));
      const _endOfMonth = toISODateString(endOfMonth(now));
      return (
        startDate === _startOfMonth &&
        endDate === _endOfMonth
      );
    }
  },
  {
    label: "Last Week",
    isSelected: (range: DateRange) => {
      const {startDate, endDate} = range;
      const now = new Date();

      const lastWeekStart = toISODateString(startOfWeek(subWeeks(now, 1)));
      const lastWeekEnd = toISODateString(endOfWeek(subWeeks(now, 1)));
      return (
        startDate === lastWeekStart &&
        endDate === lastWeekEnd
      );
    }
  },
  {
    label: "Last Month",
    isSelected: (range: DateRange) => {
      const {startDate, endDate} = range;
      const now = new Date();
      const lastMonthStart = toISODateString(startOfMonth(subMonths(now, 1)));
      const lastMonthEnd = toISODateString(endOfMonth(subMonths(now, 1)));
      return (
        startDate === lastMonthStart &&
        endDate === lastMonthEnd
      );
    }
  },
  customStaticRanges_three_month[0]
];

interface Props {
  onSelect: (range: any) => void;
  inputRange?: DateRange;
}

interface SelectionRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

export default function DatePicker({ onSelect, inputRange }: Props) {
  const [selectionRange, setSelectionRange] = useState<SelectionRange>({
    startDate: inputRange ? normalizeDate(new Date(inputRange.startDate)) : customStaticRanges_three_month[0].range().startDate,
    endDate: inputRange ? normalizeDate(new Date(inputRange.endDate)) : customStaticRanges_three_month[0].range().endDate,
    key: "selection"
  });
  const [date, setDate] = useState<string>("");
  const [months, setMonths] = useState<number>(2);
  const isDesktop = useMediaQuery({ minWidth: 767 })

  const handleSelect = (range: RangeKeyDict) => {
    if (range.selection && range.selection.startDate && range.selection.endDate) {
      const rangeSelection = {
        startDate: range.selection.startDate,
        endDate: range.selection.endDate,
        key: "selection"
      };
      setSelectionRange(rangeSelection);
      // setSelectionRange(range.selection);
      // onSelect(range.selection);
    }
  };

  const checkSelectedRange = (range: DateRange) => {
    for (const predefinedRange of predefinedRanges) {
      if (predefinedRange.isSelected(range)) {
        return predefinedRange.label;
      }
    }
    const {startDate, endDate} = range;
    if (startDate == endDate) {
      return startDate;
    }
    return `${startDate + " ~ " + endDate}`;
  };

  useEffect(() => {
    onApplyPicker();
    // setDate(checkSelectedRange(selectionRange));
  }, []);

  const onApplyPicker = useCallback(() => {
    if (selectionRange) {
      const startDate = toISODateString(selectionRange.startDate);
      const endDate = toISODateString(selectionRange.endDate);
      const range: DateRange = { startDate, endDate };

      setDate(checkSelectedRange(range));
      onSelect(range);
      onCancelPicker();
    }
  }, [selectionRange]);

  const onCancelPicker = useCallback(() => {
    setIsOpen(false)
  }, [selectionRange]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if(!isOpen) {
  //     console.log('close popup over');
  //   }
  // }, [isOpen])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMonths(1);
      }
      else {
        setMonths(2);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set the initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div ref={popoverRef}>
      <Popover
        placement="bottom-start"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0, y: 25 }
        }}
        open={isOpen}
        handler={() => setIsOpen(!isOpen)}
      >
        <PopoverHandler>
          <Button placeholder className="flex items-center gap-2 rounded-md w-full" size="sm" color="white">
            <MdOutlineDateRange size={15} />
            <p className="text-xs font-normal capitalize">{date}</p>
          </Button>
        </PopoverHandler>
        <PopoverContent placeholder>
          <div style={isDesktop ? {} : { width: '300px', overflowX: 'scroll', paddingBottom: '10px' }}>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
              months={months}
              direction="horizontal"
              staticRanges={combinedStaticRanges}
              footerContent={!isDesktop && <div className="w-full flex gap-4">
                <Button
                  className="border capitalize w-20"
                  placeholder
                  color="white"
                  size="sm"
                  onClick={onCancelPicker}
                >
                  Cancel
                </Button>
                <Button
                  className="border capitalize w-20"
                  placeholder
                  color="white"
                  size="sm"
                  onClick={onApplyPicker}
                >
                  Apply
                </Button>
              </div>}
            />
            {isDesktop && <div className="w-full flex justify-end gap-4">
              <Button
                className="border capitalize w-20"
                placeholder
                color="white"
                size="sm"
                onClick={onCancelPicker}
              >
                Cancel
              </Button>
              <Button
                className="border capitalize w-20"
                placeholder
                color="white"
                size="sm"
                onClick={onApplyPicker}
              >
                Apply
              </Button>
            </div>}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
