import CustomInput from "@/components/UI/CustomInput";
import { apiForgetPassword } from "@/shared/api/user";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "@/components/UI/PrimaryToast";
import { Button } from "@material-tailwind/react";

const ForgetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    if(isLoading) return;
    setErrorMessage("");
    setShowError(false);

    if (email) {
      setIsLoading(true);

      const res = await apiForgetPassword({
        email
      });
      if (res?.success) {
        showToast("success", t("msg_sent_reset_link"));
        navigate("/");
      } else {
        setErrorMessage(res?.error ?? res);
        setShowError(true);
      }
      setIsLoading(false);
    }
    else {
      setErrorMessage("Please enter your email.");
      setShowError(true);
    }
  };

  const renderError = () => {
    if (showError) {
      return <p className="w-full text-red-500 mt-2 -mb-5 font-regular font14">{errorMessage}</p>;
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-200 px-3">
      <div className=" flex flex-col text-black w-full max-w-lg px-3">
        <p className="text-2xl text-center font-bold mb-3">{t("forget_pwd")}</p>
        <p className="text-center opacity-50 mb-6">{"Enter your email and we'll send you a link to reset your password"}</p>
        <CustomInput
          label={t("email")}
          type="email"
          value={email}
          color="black"
          onChangeHandler={(e) => {
            setEmail(e.target.value);
          }}
        />
        {renderError()}
        <Button
          className="justify-center capitalize text-base py-2.5 mt-10"
          fullWidth
          placeholder
          size={"sm"}
          color="blue"
          loading={isLoading}
          onClick={handleSubmit}
        >
          {t("submit")}
        </Button>
        <div className="flex justify-center items-center text-sm mt-4">
          <Link to={`/login`} className="ml-2 text-sm text-blue-500">
            {t("back_to_login")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordPage