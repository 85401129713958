import SideMenu from '@/components/App/SideMenu'
import React, { useEffect, useState } from 'react'
import { Profile } from './Account/Profile'
import { Roles } from './Account/Roles'
import { ChangePassword } from './Security/ChangePassword'
import Shopify from './Socials/Shopify'
import Google from './Socials/Google'
import Meta from './Socials/Meta'
import Email from './Socials/Email'
import { IoCloseOutline } from "react-icons/io5";
import { useLocation, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SettingsPage = () => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 767 });
  const query = useQuery();
  const shopifyShopName = query.get("shopify");
  const googleId = query.get("google");
  const facebookId = query.get("facebook");
  const klaviyoId = query.get("klaviyo");
  const [selected, setSelected] = useState<string>('Profile');
  const onSelect = (key: string) => setSelected(key);

  useEffect(() => {
    if (shopifyShopName) {
      setSelected("Shopify");
    }
    if(googleId) {
      setSelected("Google");
    }
    if(facebookId) {
      setSelected("Meta");
    }
    if(klaviyoId) {
      setSelected("Email");
    }
  }, [shopifyShopName, googleId, facebookId, klaviyoId]);

  const renderCloseButton = () => {
    return (
      <div className='absolute right-3'>
        <IoCloseOutline size={26} className='cursor-pointer' onClick={() => navigate('/')} />
      </div>
    );
  }

  return (
    <div className='max-w-7xl mx-auto h-full flex sm:flex-row flex-col text-black sm:p-6 p-3'>
      <SideMenu onSelect={onSelect}/>
      { !isDesktop && renderCloseButton() }
      <div className='flex-1 sm:pl-9 pl-0 sm:pt-0 pt-3 relative'>
        { isDesktop && renderCloseButton() }
        { selected === 'Profile' && <Profile /> }
        { selected === 'Roles' && <Roles /> }
        { selected === 'Change Password' && <ChangePassword /> }
        { selected === 'Shopify' && <Shopify storeName={shopifyShopName!}/> }
        { selected === 'Google' && <Google gId={googleId!}/> }
        { selected === 'Meta' && <Meta fbId={facebookId!}/> }
        { selected === 'Email' && <Email klaviyoId={klaviyoId!}/> }
      </div>
    </div>
  )
}

export default SettingsPage