import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Select, Option } from "@material-tailwind/react";
import { channels } from "@/shared/utils/constants";
import { Channel } from '@/types/enum';
import { channelTypeToIndex, indexToChannelType } from '@/shared/utils/utils';

interface Props {
  channelType: Channel;
}

function ChannelSelect({channelType}: Props) {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(channelTypeToIndex[channelType].toString());
  const handleChange = (value: any) => {
    setSelectedOption(value);
    onChangeChannel(parseInt(value));
  };
  const onChangeChannel = (index: number) => {
    const newChannelType = indexToChannelType[index];
    if(newChannelType == Channel.AllChannels) {
      navigate('/');
    } else if(newChannelType == Channel.MetaAds) {
      navigate('/ads/meta');
    } else if(newChannelType == Channel.GoogleAds) {
      navigate('/ads/google');
    } else if(newChannelType == Channel.Shopify) {
      navigate('/ads/shopify');
    } else if(newChannelType == Channel.Email) {
      navigate('/ads/email');
    }
  }
  return (
    <div className="z-50">
      <Select placeholder
        value={selectedOption}
        onChange={handleChange}
        className="border-0 text-xs font-normal"
        labelProps={{ className: "hidden" }}
        containerProps={{ className: "!min-w-[130px] !h-8 transition-all rounded-md bg-white shadow-md shadow-blue-gray-500/10 hover:shadow-lg hover:shadow-blue-gray-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none" }}
        menuProps={{ className: "font-normal text-xs z-50" }}
      >
        {channels.map((channel, index) => (
          <Option key={index} value={index.toString()}>{channel.label}</Option>
        ))}
      </Select>
    </div>
  )
}

export default ChannelSelect