import { apiGetHelp } from "@/shared/api/common";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import { Spinner } from "@material-tailwind/react";

function HelpDetail() {
  let { slug } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const getHelpData = async () => {
      const res = await apiGetHelp({ id: slug! });
      if (res?.success) {
        setData(res.data);
      }
    };
    getHelpData();
  }, []);

  const onBack = () => {
    navigate("/help");
  }

  return (
    <div className="max-w-5xl mx-auto h-screen text-black sm:p-6 p-2.5">
      {data ? (
        <Scrollbars className="h-full w-full">
          <div className="md:px-8 px-2.5 pt-4 md:pb-12 pb-7">
            <p className="mb-6 text-sm font-light cursor-pointer hover:text-blue-500" onClick={onBack}>{"• Help"} </p>
            <p className="font-fair md:text-4.5xl text-3xl leading-tight mb-6">{data?.title}</p>
            <p className=" text-lg font-medium mb-6">{data?.description}</p>
            <img src={data?.image || require("@/assets/images/png/default.jpg")} alt="help-image" className="w-full aspect-video object-cover mb-6" />
            <div dangerouslySetInnerHTML={{ __html: data?.content }}></div>
          </div>
        </Scrollbars>
      ) : (
        <div className="flex justify-center items-center">
          <Spinner color="blue" />
        </div>
      )}
    </div>
  );
}

export default HelpDetail;
