import CustomInput from "@/components/UI/CustomInput";
import PasswordInput from "@/components/UI/PasswordInput";
import ShapeButton, { ShapeType } from "@/components/UI/ShapeButton";
import { apiRegister } from "@/shared/api/user";
import { validateEmail } from "@/shared/utils/utils";
import "./index.styles.scss";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { showToast } from "@/components/UI/PrimaryToast";
import {
  client_type_options,
  customer_role_options
} from "@/shared/utils/constants";
import { useNavigate } from "react-router-dom";
import PasswordStrengthBar from 'react-password-strength-bar';
import { Tabs, TabsHeader, Tab, Select, Option, Checkbox, Typography, TabsBody, TabPanel, Button } from "@material-tailwind/react";

const SignUpPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState<boolean>(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [clientType, setClientType] = useState(client_type_options[0].value);
  const [customerRole, setCustomerRole] = useState(customer_role_options[0].value);
  const [isRegistering, setIsRegistering] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validatePassword = (password: string): boolean => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return regex.test(password);
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsValid(validatePassword(newPassword));
  }

  const handleChangeRole = (value: any) => {
    setCustomerRole(value);
  };

  const handleTabClientType = (value: any) => {
    setClientType(value);
  };

  const onRegister = async () => {
    if (isRegistering) return;

    setShowError(false);
    setErrorMessage("");

    if (firstName.length == 0 || lastName.length == 0) {
      setShowError(true);
      setErrorMessage(t("add_your_name_sign_up"));
      return;
    }

    if (!validateEmail(email)) {
      setShowError(true);
      setErrorMessage(t("msg_invalid_email_entered"));
      return;
    }

    if (
      password === confirmPassword &&
      !!firstName &&
      !!lastName &&
      !!email &&
      !!password &&
      !!companyName &&
      !!clientType &&
      !!customerRole
    ) {
      if (!isValid) {
        setShowError(true);
        setErrorMessage(t("msg_too_easy_to_guess"));
        return;
      }
      setShowError(false);
      setIsRegistering(true);

      const res = await apiRegister({
        firstName,
        lastName,
        email,
        password,
        companyName,
        clientType,
        customerRole
      });
      if (res?.success) {
        showToast("success", t("msg_account_created"));
        navigate("/login");
      } else {
        setErrorMessage(res?.error);
        setShowError(true);
      }
      setIsRegistering(false);
    } else if (!!password && password !== confirmPassword) {
      setErrorMessage(t("msg_password_mismatch"));
      setShowError(true);
    }
  };

  const renderError = () => {
    if (showError) {
      return (
        <p className="w-full text-sm text-red-500 mt-2 -mb-5 font-regular font14">
          {errorMessage}
        </p>
      );
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-200 py-3 px-3">
      <div className="flex flex-col text-black w-full max-w-lg px-3 overflow-y-auto">
        <p className="text-2xl text-center font-bold mb-2">{t("SIGN_UP")}</p>
        <CustomInput
          label={t("first_name")}
          type="text"
          value={firstName}
          color="black"
          onChangeHandler={(e) => {
            setFirstName(e.target.value);
          }}
        />
        <CustomInput
          label={t("last_name")}
          type="text"
          value={lastName}
          color="black"
          onChangeHandler={(e) => {
            setLastName(e.target.value);
          }}
        />
        <CustomInput
          label={t("email")}
          type="email"
          value={email}
          color="black"
          onChangeHandler={(e) => {
            setEmail(e.target.value);
          }}
        />
        <PasswordInput
          label={t("password")}
          name="password"
          color="black"
          onChangeHandler={handleChangePassword}
        />
        <PasswordStrengthBar className="PasswordStrengthBar" password={password} minLength={8} />
        <p className="mb-3 -mt-1 text-sm" style={{ color: !isValid ? 'red' : 'gray' }}>{t("msg_valid_password")}</p>
        <PasswordInput
          label={t("confirm_password")}
          name="confirm password"
          color="black"
          onChangeHandler={(e) => {
            setConfirmPassword(e.target.value);
          }}
        />
        <CustomInput
          label={"Company name"}
          type="text"
          value={companyName}
          color="black"
          onChangeHandler={(e) => {
            setCompanyName(e.target.value);
          }}
        />
        <Tabs className="w-2/5 min-h-9" value={clientType}>
          <TabsHeader placeholder className="p-0"
            indicatorProps={{ className: "bg-blue-500" }}
          >
            {client_type_options.map(({ value }, index) => (
              <Tab placeholder className="text-sm py-2" key={index} value={value} onClick={() => handleTabClientType(value)}>
                {value}
              </Tab>
            ))}
          </TabsHeader>
        </Tabs>
        <label className="text-black text-sm font-semibold mb-1 mt-3">
          {t("customer_role")}
        </label>
        <div className="min-h-[18px] mb-4">
          <Select placeholder value={customerRole} onChange={handleChangeRole}
            className="border border-blue-gray-200 focus:border-blue-600"
            labelProps={{ className: "hidden" }}>
            {customer_role_options.map(({ value }, index) => (
              <Option key={index} value={value}>{value}</Option>
            ))}
          </Select>
        </div>
        <div className="-ml-3">
          <Checkbox crossOrigin color="blue" label={
            <Typography placeholder color="blue-gray" className="flex text-sm font-medium">
              I Accept the
              <Typography placeholder
                as="a"
                href="#"
                color="blue"
                className="font-medium text-sm transition-colors hover:text-blue-700"
              >
                &nbsp;terms and conditions
              </Typography>
            </Typography>
          } />
        </div>
        {renderError()}
        <Button
          className="justify-center capitalize text-base py-2.5 mt-5"
          fullWidth
          placeholder
          size={"sm"}
          color="blue"
          loading={isRegistering}
          onClick={onRegister}
        >
          {t("CREATE_ACCOUNT")}
        </Button>
        <p className="text-sm font-medium text-blue-500 cursor-pointer mx-auto mt-4"
          onClick={() => { navigate("/login"); }}>Cancel</p>
      </div>
    </div>
  );
};

export default SignUpPage;
