import HttpClient from "./apiClient";

export async function apiGetPrompts(params: {
  channel?: string;
  query?: string;
  limit: number;
  offset: number;
})
{
  try {
    const response = await HttpClient.get("/prompts", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetRecentPrompts(params: {
  promptId?: string | null;
}) {
  try {
    const response = await HttpClient.get(
      "/prompts/recent", {
        ...params
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiSendPrompt(params: {
  data: any;
}) {
  try {
    const response = await HttpClient.postWithToken(
      "/prompts/recent",
      {
        ...params
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletePrompt(params: {
  promptId: string;
}) {
  try {
    const response = await HttpClient.post(
      "/prompts/recent/delete",
      {
        ...params
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetHelpList(params: {
  query?: string;
  limit: number;
  offset: number;
})
{
  try {
    const response = await HttpClient.get("/help", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}


export async function apiGetHelp(params: {
  id: string;
})
{
  try {
    const response = await HttpClient.get("/help/detail", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
