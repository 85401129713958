import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import your translation files
import translationEN from "./locales/en.json";

export const supportedLanguages = ["en"] as const;
export const defaultNS = "global";
const resources = {
  en: {
    translation: translationEN, // English translations
  },
};

// Set up i18next
i18n.use(LanguageDetector).use(initReactI18next);

if (!i18n.isInitialized) {
  i18n.init({
    fallbackLng: "en",
    supportedLngs: supportedLanguages,
    // lng: userLanguage,
    load: "languageOnly",
    react: {
      useSuspense: false,
    },
    resources,
    debug: true,
  });

  console.log(i18n.language);
}
