import { apiGetShopifyAccInfo } from "@/shared/api/user";
import { Button, Input, Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { FaShopify } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { RootState } from "@/redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import appAction from "@/redux/app/action";
import { useCookies } from "react-cookie";
import { showToast } from "@/components/UI/PrimaryToast";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface Props {
  isConnected: boolean;
  setConnected: (connected: boolean) => void;
}

const ShopifyConnect = ({isConnected, setConnected}: Props) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const shopifyShopName = query.get("shopify");
  const [cookies, setCookie] = useCookies(["shopify"]);
  const [connectedShopify, setConnectedShopify] = useState(isConnected);
  const [openModal, setOpenModal] = useState(false);
  const [shopName, setShopName] = useState("");

  useEffect(() => {
    setConnectedShopify(isConnected);
  }, [isConnected]);

  useEffect(() => {
    if (shopifyShopName) {
      shopifyConnect(shopifyShopName);
    }
  }, [shopifyShopName]);

  const shopifyConnect = async (shopifyShopName: string) => {
    const result = await apiGetShopifyAccInfo({
      shopName: shopifyShopName,
    });

    if (result?.success) {
      setCookie("shopify", "success");
      showToast(
        "success",
        "Successfully connected to Shopify Account"
      );
      dispatch(appAction.loadUserShoipfyInfo({
        shopName: shopName,
      }));
      setConnected(true);
      setConnectedShopify(true);
    } else {
      console.log(result?.error ?? result);
      setConnectedShopify(false);
      setConnected(false);
    }
  }

  const onConnect = async () => {
    window.location.href = process.env.REACT_APP_SERVER_URL + `/user/shopify/oauth_url/?shop=${shopName}.myshopify.com`;
  };

  const onOpenModal = () => setOpenModal(true);

  const onConfirm = () => {
    if(shopName) {
      setOpenModal(false);
      onConnect();
      setShopName("");
    }
  };

  return (
    <>
      <li className="py-3 sm:py-4">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            <FaShopify size={40} color="green"/>
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-base font-medium text-gray-900 truncate">
              Shopify
            </p>
            <p className="text-sm text-gray-500 truncate">
              {connectedShopify ? "Connected" : "No account connected"}
            </p>
          </div>
          <Button
            placeholder
            color="green"
            variant={connectedShopify ? "outlined" : "filled"}
            size={"sm"}
            onClick={() => {
              !connectedShopify && onOpenModal();
            }}
          >
            {connectedShopify ? "connected" : "connect"}
          </Button>
        </div>
      </li>

      <Dialog placeholder open={openModal} handler={onOpenModal}>
        <DialogHeader placeholder>Shopify Shop</DialogHeader>
        <DialogBody placeholder>
          <div className="w-72">
            <Input crossOrigin label="Shop name" value={shopName} onChange={(e) => {setShopName(e.target.value);}} />
          </div>
        </DialogBody>
        <DialogFooter placeholder>
          <Button
            placeholder
            variant="gradient"
            color="green"
            onClick={onConfirm}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ShopifyConnect;
