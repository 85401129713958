import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const DashboardPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % 3); // Assuming there are 3 slides
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + 3) % 3); // Assuming there are 3 slides
  };
  return (
    <div className='mx-auto max-w-3.5xl'>
      <Carousel
        selectedItem={currentIndex}
        axis="vertical"
        showThumbs={false}
        infiniteLoop={false}
        showArrows={false}
        showIndicators={false}
        onChange={(index) => setCurrentIndex(index)}
      >
        <div>
          {/* <img src={require("@/assets/images/png/computer.png")} /> */}
          <div className='w-full h-screen bg-yellow-400'></div>
        </div>
        <div>
          {/* <img src={require("@/assets/images/png/computer.png")} /> */}
          <div className='w-full h-screen bg-red-300'></div>
        </div>
        <div>
          {/* <img src={require("@/assets/images/png/computer.png")} /> */}
          <div className='w-full h-screen bg-blue-gray-400'></div>
        </div>
      </Carousel>
      <div className='absolute top-10 left-10'>
      <button onClick={goToPrevious}>Prev</button>
      <button onClick={goToNext} className='ml-3'>Next</button>
      </div>
    </div>
  )
}

export default DashboardPage