import React, { useEffect } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import { publicRoutes } from "@/routes/public";
import { privateRoutes } from "@/routes/private";
import { fallbackRoute } from "@/routes/fallback";
import { PrivateRoute } from "@/routes/PrivateRoute";
import { AppRoute } from "@/types/basic";
import { styled } from "styled-components";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { apiLogout } from "@/shared/api/user";
import { removeToken } from "@/shared/api/storageHelper";
import appAction from "@/redux/app/action";

export function AppRoutes() {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["facebook", "google", "shopify"]);

  useEffect(() => {
    const interval = setInterval(() => {
      const token = Cookies.get("accessToken");
      if(!token ||
        token === "" ||
        token === "undefined")
      {
        onLogout();
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const onLogout = async () => {
    await removeToken();
    localStorage.removeItem("userId");
    dispatch(appAction.successLogout());
    // Cookies.set("socialsConnected", 'fail');
    // setCookie("facebook", 'fail');
    // setCookie("google", 'fail');
    // setCookie("shopify", 'fail');
  }

  const parseRouteObjects = (routes: AppRoute[]): RouteObject[] => {
    return routes.map((route) => ({
      path: route.path,
      element: privateRoutes.includes(route)
        ? route.layout({
            children: <PrivateRoute>{route.element}</PrivateRoute>
          })
        : route.layout({ children: route.element })
    }));
  };

  const publicRouteObjects = parseRouteObjects(publicRoutes);
  const privateRouteObjects = parseRouteObjects(privateRoutes);
  const fallbackRouteObjects = parseRouteObjects(fallbackRoute);

  const routes = [
    ...publicRouteObjects,
    ...privateRouteObjects,
    ...fallbackRouteObjects
  ];

  const allRoutes = useRoutes(routes);

  return (
    <React.Fragment>
      <Container>{allRoutes}</Container>
    </React.Fragment>
  );
}

const Container = styled.div`
  min-height: 100vh;
  background-color: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.textColor};
`;
