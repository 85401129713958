import { Spinner } from "@material-tailwind/react";
import React, { ReactNode } from "react";
import { styled } from "styled-components";
import { FiArrowDownRight, FiArrowUpRight } from "react-icons/fi";
import { formatLargeNumber } from "@/shared/utils/shopify";

interface Props {
  label?: string;
  value?: number;
  percent?: any;
  isShowPercent?: boolean;
  bgColor?: string;
  selected?: boolean;
  currency?: string;
  isLoading?: boolean;
  onChangeHandler?: (e: any) => void;
}

export default function CustomCard({
  label = "Sample",
  value = 0,
  percent = 15,
  isShowPercent = true,
  bgColor = "#f0f4f9",
  selected = false,
  currency = "$",
  isLoading = false,
  onChangeHandler
}: Props) {
  return (
    <Container
      selected={selected}
      bgColor={bgColor}
      className="w-full p-2 rounded-md cursor-pointer text-left"
      onClick={onChangeHandler}
    >
      <div className="flex flex-col justify-between h-full">
        <p className="text-[11px] tracking-tighter">{label}</p>
        { !isLoading ?
          <div className="row_space_between">
            <p className="font-bold text-base text-left">
              {currency == "%" ? `${formatLargeNumber(value)}${currency}` : `${currency}${formatLargeNumber(value)}`}
            </p>
            {isShowPercent && (
              <div className="row_flex_center">
                {percent == 0 ? (
                  <></>
                ) : percent > 0 ? (
                  <FiArrowUpRight color="green" size={12} />
                ) : (
                  <FiArrowDownRight color="red" size={12} />
                )}
                <p className="text-[10px]" style={{ color: percent > 0 ? "green" : "red" }}>
                  {Math.abs(percent)}%
                </p>
              </div>
            )}
          </div> : <Spinner />
        }
      </div>
    </Container>
  );
}

const Container = styled.div<{ selected?: boolean; bgColor?: string }>`
  box-shadow: ${({ selected }) =>
    selected
      ? "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
      : "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"};
  background-color: ${({ selected, bgColor }) => (selected ? "#fff" : "#f0f4f9")};
`;
