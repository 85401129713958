import CustomInput from "@/components/UI/CustomInput";
import PasswordInput from "@/components/UI/PasswordInput";
import appAction from "@/redux/app/action";
import { apiGetSocialConnects, apiLogin } from "@/shared/api/user";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "@/components/UI/PrimaryToast";
import Cookies from "js-cookie";
import { Button } from "@material-tailwind/react";

const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogging, setIsLogging] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onLogin = async () => {
    setShowError(false);
    if (!!email && !!password && !isLogging) {
      setIsLogging(true);

      const res = await apiLogin({
        email,
        password
      });
      if (res?.success) {
        Cookies.set("accessToken", res.token);
        localStorage.setItem("userId", res.userData.id);
        dispatch(appAction.successLogin(res.userData));
        showToast("success", t("msg_login_success"));

        const result = await apiGetSocialConnects();
        if(result?.success) {
          if(result.socials?.facebook.status && result.socials?.google.status && result.socials?.shopify.status) {
            dispatch(appAction.loadUserShoipfyInfo({
              shopName: result.socials?.shopify.id
            }));
            Cookies.set("socialsConnected", "success");
            const visitedURL = Cookies.get('visitedURL') || '/';
            navigate(`${visitedURL}`);
          }
          else {
            Cookies.set("socialsConnected", "fail");
            navigate("/connects");
          }
        }
      } else {
        setErrorMessage(res?.error ?? res);
        setShowError(true);
      }
      setIsLogging(false);
    } else if (password === "") {
      showToast("error", t("msg_enter_your_password_to_login"));
    }
  };

  const ForgetPasswordPage = () => {
    navigate("/forget-password");
  }

  const renderError = () => {
    if (showError) {
      return <p className="w-full text-red-500 mt-2 -mb-5 font-regular font14">{errorMessage}</p>;
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-200 px-3">
      <div className=" flex flex-col text-black w-full max-w-lg px-3">
        <p className="text-2xl text-center font-bold mb-6">{t("LOG_IN")}</p>
        <CustomInput
          label={t("email")}
          type="email"
          value={email}
          color="black"
          onChangeHandler={(e) => {
            setEmail(e.target.value);
          }}
        />
        <div className="h-3"></div>
        <PasswordInput
          label={t("password")}
          name="password"
          color="black"
          onChangeHandler={(e) => {
            setPassword(e.target.value);
          }}
        />
        <p className="text-blue-500 text-end text-sm cursor-pointer" onClick={ForgetPasswordPage}>
          {t("forget_pwd")}{"?"}
        </p>
        {renderError()}
        <Button
          className="justify-center capitalize text-base py-2.5 mt-10"
          fullWidth
          placeholder
          size={"sm"}
          color="blue"
          loading={isLogging}
          onClick={onLogin}
        >
          {t("Continue")}
        </Button>
        <div className="flex justify-center items-center text-sm mt-4">
          <p>{t("not_member_yet")}</p>
          <Link to={`/signup`} className="ml-2 text-sm text-blue-500">
            {t("SIGN_UP")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
