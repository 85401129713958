export const defaultValue = "Hello ATMK!";

export const client_type_options = [
  { value: "Brand" },
  { value: "Agency" }
];

export const customer_role_options = [
  { value: "Agency Owner" },
  { value: "Brand Owner" },
  { value: "CMO" },
  { value: "Marketing Manager" },
  { value: "Retention Manager" },
  { value: "Consultant" },
  { value: "Operations" },
  { value: "Finance" },
];

export const channels = [
  { label: "All Channels" },
  { label: "Meta Ads" },
  { label: "Google Ads" },
  { label: "Shopify" },
  { label: "Email" }
];
