import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "@/routes";
import "./App.scss";
import "./shared/translation";
import { Provider } from "react-redux";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import ThemeProvider from "@/shared/contexts/ThemeProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Provider store={store.store}>
          <PersistGate loading={null} persistor={store.persistor}>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
            <ToastContainer />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
