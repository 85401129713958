import HttpClient from "./apiClient";

export async function apiSendDataRequest(params: { data: any; hmac: string }) {
  try {
    const response = await HttpClient.post(
      "/webhooks/customers/data_request",
      {
        ...params
      },
      { headers: { "X-Shopify-Hmac-Sha256": params.hmac } }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiSendCustomerRedact(params: {
  data: any;
  hmac: string;
}) {
  try {
    const response = await HttpClient.post(
      "/webhooks/customers/redact",
      {
        ...params
      },
      { headers: { "X-Shopify-Hmac-Sha256": params.hmac } }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiSendShopRedact(params: { data: any; hmac: string }) {
  try {
    const response = await HttpClient.post(
      "/webhooks/shop/redact",
      {
        ...params
      },
      { headers: { "X-Shopify-Hmac-Sha256": params.hmac } }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyAllOrders(params: {
  shopName: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/allOrders", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifySales(params: {
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/sales", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyCustomersAll(params: {
  shopName: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/customers/all", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyCustomersNew(params: {
  shopName: string;
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/customers/new", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyCustomersRepeat(params: {
  shopName: string;
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/customers/repeat", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyRepurchaseFrequency(params: {
  shopName: string;
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/orders/repurchaseFreq", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

