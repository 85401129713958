import React from 'react';

function UCheckIcon(props: { size: any; color: any; }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '25'}
      height={props.size ?? '24'}
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill={props.color ?? '#fff'}
        d="M19.209 7.21a1 1 0 00-1.42 0l-7.45 7.46-3.13-3.14A1.021 1.021 0 105.788 13l3.84 3.84a1 1 0 001.42 0l8.16-8.16a1 1 0 000-1.47z"
      ></path>
    </svg>
  );
}

export default UCheckIcon;
