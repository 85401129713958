import styled from "styled-components";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useState } from "react";

interface Props {
  label?: string;
  name: string;
  color?: string;
  onChangeHandler?: (e: any) => void;
}

export default function PasswordInput({label, name, color, onChangeHandler}: Props) {
  const [type, setType] = useState("password");
  const handleToggle = () => {
    if (type==='password'){
       setType('text')
    } else {
       setType('password')
    }
 }
  return (
    <div className="relative mb-3">
      <label htmlFor={label} className="text-black text-sm font-semibold">
        {label}
      </label>
      <div className="relative mt-1">
        <StyledInput
          type={type}
          name={name}
          color={color}
          placeholder={label}
          required
          onChange={onChangeHandler}
          className="w-full text-sm bg-transparent border rounded-lg border-blue-gray-200 py-2 pl-5 pr-4 focus:border-blue-600 focus:outline-none"
          autoComplete="current-password"
        />
        <span className="absolute right-0 top-0 h-full w-11" onClick={handleToggle}>
          <span className="w-full h-full flex items-center">
          {type=='password' ? <IoMdEyeOff size={25} color={color}/> : <IoMdEye size={25} color={color}/>}
          </span>
        </span>
      </div>
    </div>
  );
}

const StyledInput = styled.input<{color?: string}>`
  &:focus {
    outline: none;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 500px ${({ theme }) => theme.backgroundColor}
      inset !important;
    box-shadow: 0 0 0 500px ${({ theme }) => theme.backgroundColor} inset !important;
    -webkit-text-fill-color: ${({ color, theme }) => color ? color : theme.textColor};
  }
  color: ${({ color, theme }) => color ? color : theme.textColor};
  border-width: 1px;
`;
