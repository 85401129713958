import { FaUserCircle } from "react-icons/fa";
import { IoMdSettings, IoMdHelpCircle } from "react-icons/io";
import { LiaSignOutAltSolid } from "react-icons/lia";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Typography,
  Avatar
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/interfaces";
import appAction from "@/redux/app/action";
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import { apiLogout } from "@/shared/api/user";
import { removeToken } from "@/shared/api/storageHelper";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["facebook", "google", "shopify", "socialsConnected"]);
  const { userData } = useSelector((state: RootState) => state.app);

  const onLogout = async () => {
    const res = await apiLogout();
    if (!res?.success) {
      return;
    }
    await removeToken();
    localStorage.removeItem("userId");
    dispatch(appAction.successLogout());
    // Cookies.set("socialsConnected", 'fail');
    // setCookie("facebook", 'fail');
    // setCookie("google", 'fail');
    // setCookie("shopify", 'fail');
    // navigate("/login");
  }

  return (
    <nav className="text-white">
      <Menu placement="top-start">
        <MenuHandler>
          {/* <Button placeholder>{userData?.firstName}</Button> */}
          <div className="cursor-pointer w-9">
            {userData?.profilePicture != "" ? <Avatar placeholder src={userData?.profilePicture} alt="avatar" size='sm' /> :
            <Avatar placeholder src={require("@/assets/images/png/user.png")} alt="avatar" size='sm' />}
          </div>
        </MenuHandler>
        <MenuList placeholder>
          <MenuItem placeholder className="flex items-center gap-2" onClick={()=>{
            navigate("/settings");
          }}>
            <FaUserCircle size={17} />
            <Typography placeholder variant="small" className="font-medium">
              My Profile
            </Typography>
          </MenuItem>
          <MenuItem placeholder className="flex items-center gap-2" onClick={()=>{
            navigate("/settings");
          }}>
            <IoMdSettings size={19} />
            <Typography placeholder variant="small" className="font-medium">
              Settings
            </Typography>
          </MenuItem>
          <MenuItem placeholder className="flex items-center gap-2" onClick={()=>{
            navigate("/help");
          }}>
            <IoMdHelpCircle size={19} />
            <Typography placeholder variant="small" className="font-medium">
              Help
            </Typography>
          </MenuItem>
          <hr className="my-3" />
          <MenuItem placeholder className="flex items-center gap-2" onClick={onLogout}>
            <LiaSignOutAltSolid size={19} />
            <Typography placeholder variant="small" className="font-medium">
              Sign Out
            </Typography>
          </MenuItem>
        </MenuList>
      </Menu>
    </nav>
  );
};

export default Header;
