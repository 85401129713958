import React from "react";

export function SettingLayout({ children }: React.PropsWithChildren): JSX.Element {

  return (
    <>
      <div className="bg-gray-50 sm:h-screen h-full">
        <div
          id="main-content"
          className="w-full h-full relative"
        >
          {children}
        </div>
      </div>
    </>
  );
}
